import { Component, OnInit } from '@angular/core';

import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';
import { DialogComponent } from 'src/app/DialogComponent';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonModalComponent } from 'src/app/common-modal/common-modal.component';
import { MatDialog } from '@angular/material';
import { TaskModalComponent } from 'src/app/task-modal/task-modal.component';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit {
    project_form: any = {};
    loader: boolean = false;
    encrpt_id: any = '';
    filter: any = {};
    start: any = 0;
    pagelimit: any = 200;
    search: any = {};
    task_count: any = {};
    rqst_count: any = '';
    total_page: any = ''
    pagenumber: any = '';
    project_list: any = [];
    project_count: any = [];
    user_list_data: any = [];
    temp_project_list: any = [];
    data_found: any = '';
    current_month: any = '';
    mainTab: any = 'project_wise';
    currentActiveTab: any = '';
    approval_list: any = [];
    approval_data: any = {};
    hours_list: any = [];
    mins_list: any = [];
    date: any
    today_date: any
    sr_no: number;
    days: any = [];
    item: any = [];
    user_list: any = [];
    unholdData: any = {}
    TaskIdArr: any = [];
    task_id: any;
    userDataArray: any = [];
    user_count: any = [];
    project: any = {};
    loading: any = '';
    task: any = [];
    project_task_log: any = [];
    data: any = {};
    currentDate: any;
    btnFlag: boolean = false;
    maxDate: any;
    status: any = {};
    url: any = '';
    quotationListArray: any = [];

    private formatDate(date: Date): string {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = date.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }



    constructor(public db: DatabaseService, public route: ActivatedRoute, public session: SessionService, public router: Router, public alrt: MatDialog, public dialog: DialogComponent, public navCtrl: NgxNavigationWithDataComponent) {
        this.sessiondata = this.session.GetSession();
        console.log(session);
        this.url = this.db.upload + 'client_task_doc/';
        this.today_date = new Date().toISOString().slice(0, 10);
        this.currentDate = new Date();
        this.search.status = 'Pending';
        this.filter = this.db.getData();

        this.mainTab = this.db.getSearchData();

        this.currentActiveTab = 'project_wise';
        for (let index = 1; index < 31; index++) {
            const element = index;
            this.days.push(element);
        }

        const today = new Date();
        today.setDate(today.getDate() + 3);
        this.maxDate = this.formatDate(today);


        if (this.mainTab == 'user') {
            this.GetUserDataArray();
        }
        else {
            this.mainTab = 'project_wise';
        }
        this.getCount();
    }



    pervious(type) {
        this.start = this.start - this.pagelimit;

        if (type == 'project_wise') {
            this.GetProjectList();
        }
        else {
            this.GetProjectDetail();
        }

    }

    nextPage(type) {
        this.start = this.start + this.pagelimit;
        if (type == 'project_wise') {
            this.GetProjectList();
        }
        else {
            this.GetProjectDetail();
        }
    }

    ngOnInit() {

        console.log(this.status, 'status');


        this.route.params.subscribe(params => {
            console.log(params);

            if (this.route.queryParams['_value']['project_status'] != undefined) {
                this.mainTab = 'task_wise'
                this.search.status = this.route.queryParams['_value']['project_status'];
                this.GetProjectDetail();
            }
            if (this.route.queryParams['_value']['mainTab'] != undefined) {
                this.mainTab = this.route.queryParams['_value']['mainTab'];
                if (this.mainTab != 'quotation') {
                    this.status = this.route.queryParams['_value']['status'];
                    this.GetProjectDetail();
                } else {
                    this.getQuotationList();
                }
            }
            else if (this.session.abacusdesk_user.access_level == '3') {
                this.search.status = 'Closed';
                this.GetProjectList();
            }
            else {
                this.GetProjectList();
            }
        });
        this.current_month = new Date();
        this.date = new Date();
        if (this.session.abacusdesk_user.access_level != 'client' && this.session.abacusdesk_user != '') {
            setInterval(() => {
                this.getCount();
            }, 60000);
        }
    }
    date_format(type): void {
        if (type == 'date_created') {
            this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
        else if (type == 'assignedDate') {
            this.filter.assignedDate = moment(this.filter.assignedDate).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
        else if (type == 'closed_date') {
            this.filter.closed_date = moment(this.filter.closed_date).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
        else if (type == 'completed_date') {
            this.filter.completed_date = moment(this.filter.completed_date).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
        else if (type == 'reopen_date') {
            this.filter.reopen_date = moment(this.filter.reopen_date).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
        else if (type == 'verify_date') {
            this.filter.verify_date = moment(this.filter.verify_date).format('YYYY-MM-DD');
            this.GetProjectDetail();
        }
    }
    date_format1(): void {
        this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
        this.GetUserDataArray();
    }

    date_format2(type): void {
        if (type == 'date_created') this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
        if (type == 'update_on') this.filter.update_on = moment(this.filter.update_on).format('YYYY-MM-DD');
        this.getQuotationList();
    }

    unhold_task(task_id) {
        this.unholdData.task_id = task_id
        this.dialog.confirmation('You want to unhold task').then((result) => {
            if (result) {
                this.db.FetchData({ 'data': this.unholdData, 'session': this.session.abacusdesk_user }, 'master/unhold_task')
                    .subscribe(result => {
                        if (result['msg'] == 'success') {

                            this.dialog.success('Task is re-assigned', ' Successfully !');
                            this.GetProjectList()

                        }
                        else {
                            this.dialog.error('Something went wrong... Try Again !!!');
                        }

                    }, error => {
                        this.dialog.error('Something went wrong... Try Again !!!');
                        // this.loading = false;
                    });
            }
        })
    }
    refresh(type) {

        this.filter = {};

        this.pagelimit = 200;
        this.start = 0;
        this.TaskIdArr = [];
        if (type == 'project_wise') {
            this.GetProjectList();
        }
        if (type == 'user') {
            this.GetUserDataArray();
        }
        if (type == 'quotation') {
            this.db.setData({});
            this.getQuotationList();
        }

        if (type == 'task_wise') {
            this.btnFlag = false;
            this.assignId = [];
            this.GetProjectDetail();
        }
    }

    GetProjectList() {
        this.loader = true;

        this.data_found = true;
        this.approval_list = [];
        if (this.pagenumber > this.total_page) {
            this.pagenumber = this.total_page;
            this.start = this.rqst_count - this.pagelimit;
        }
        if (this.start < 0) {
            this.start = 0;
        }

        this.db.FetchData({



            search: this.search,
            filter: this.filter,
            start: this.start,
            pagelimit: this.pagelimit,
            session: this.session.abacusdesk_user,
            currentActiveTab: this.currentActiveTab

        }, 'master/new_project_list').subscribe((result) => {
            //   },'master/project_list').subscribe((result)=> {

            this.project_list = result['project_list'];
            this.temp_project_list = result['project_list'];
            this.rqst_count = result['count'];
            this.task_count = result['task_count'];
            this.project_count = result['project_count'];
            this.user_list_data = result['user_list_data'];
            this.approval_list = result['closingApproval'];


            if (this.pagenumber > this.total_page) {
                this.pagenumber = this.total_page;
                this.start = this.rqst_count - this.pagelimit;
            }
            else {
                this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
            }
            this.total_page = Math.ceil(this.rqst_count / this.pagelimit);
            this.sr_no = this.pagenumber - 1;
            this.sr_no = this.sr_no * this.pagelimit




            // this.total_page = Math.ceil(this.rqst_count / this.pagelimit);
            // this.pagenumber = (this.start / this.pagelimit) + 1;
            this.loader = false;

            this.project_list.map((x) => {
                x.edit_assign_to = false;
            });

        }, error => {

            this.dialog.error('Something went wrong !! Try Again ...');
        });

    }

    delete_user(index: any, user_id: any) {
        this.dialog.delete('User Data').then((result) => {
            if (result) {
                this.db.FetchData({ 'id': user_id, 'session': this.session.abacusdesk_user }, 'master/delete_user')
                    .subscribe(res => {
                        this.GetUserDataArray();
                        this.dialog.success('Deleted', 'User has been deleted.');
                    }, err => {
                        this.dialog.error('Something went wrong! Try Again ...');
                    });
            }
        });
    }

    MobileNumber(event: any) {

        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    GetTaskArray(event, index: any, action: any = '') {
        if (action == 'all') {
            if (event.checked == true) {
                this.task.map((item) => {
                    item.check = true;
                });
            }
            else {
                this.task.map((item) => {
                    item.check = false;
                });


            }


        } else {
            this.task[index].check = !this.task[index].check;
            this.filter.allCheck = false;
        }
        this.TaskIdArr = this.task.reduce((results, item) => {
            if (item.check == true) results.push(item.id);
            return results;
        }, []);

    }
    clear_form(myForm: any) {
        this.loading = '';
    }

    GetProjectDetail() {
        this.loader = true;

        if (this.pagenumber > this.total_page) {
            this.pagenumber = this.total_page;
            this.start = this.rqst_count - this.pagelimit;
        }
        if (this.start < 0) {
            this.start = 0;
        }
        this.db.FetchData({ 'search': this.search, 'start': this.start, 'pagelimit': this.pagelimit, 'id': this.encrpt_id, 'session': this.session.abacusdesk_user, 'filter': this.filter }, 'master/SubTaskList')
            .subscribe((result: any) => {
                this.project = result['project'];
                this.project_form = result['project'];
                this.task = result['task_list'];
                this.task_count = result['task_count']

                this.project_task_log = result['project_task_log'];
                this.user_list = result['team_member'];

                this.loader = false;
                const statusMapping = {
                    'Pending': 'pend_task',
                    'Assigned': 'assigned_task',
                    'MyTask': 'my_task',
                    'Closed': 'closed_task',
                    'Delivered': 'delivered',
                    'ReOpen': 'reOpen',
                    'Hold': 'hold_task',
                    'Trash': 'trash_task',
                    'Verified': 'verfied_task',
                    'Dispute': 'dispute_task'
                };

                if (statusMapping.hasOwnProperty(this.search.status)) {
                    this.rqst_count = result['task_count'][statusMapping[this.search.status]];
                }
                if (this.pagenumber > this.total_page) {
                    this.pagenumber = this.total_page;
                    this.start = this.rqst_count - this.pagelimit;
                }
                else {
                    this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
                }
                this.total_page = Math.ceil(this.rqst_count / this.pagelimit);
                this.sr_no = this.pagenumber - 1;
                this.sr_no = this.sr_no * this.pagelimit;
                console.log(this.sr_no)
                if (this.task.length) {
                    this.data_found = true;
                }
                else {
                    this.data_found = false;
                }

            }, error => {
                this.dialog.error('Something went wrong !!! Try again... ');
            });
    }

    GetUserDataArray() {
        this.loader = true;
        this.db.FetchData({ 'search': this.search, 'filter': this.filter, 'session': this.sessiondata.value, 'start': this.start, 'pagelimit': this.pagelimit }, 'master/user_list')
            .subscribe((result: any) => {
                this.userDataArray = result['user_list'];
                this.user_count = result['count']
                this.loader = false;

            }, error => {
                this.dialog.error('Something went wrong !!! Try again... ');
            });
    }

    openDialog(id, required_developer, project_id, type, task, edit): void {
        const dialogRef = this.alrt.open(CommonModalComponent, {
            width: (type == 'userTask' || type == 'projectDetail') ? '1024px' : '768px',
            panelClass: 'cs-modal',
            disableClose: true,
            data: {
                'project_id': project_id,
                'id': id,
                'encrpt_id': this.encrpt_id,
                'session': this.session,
                'from': 'projectList',
                'from_type': 'edit',
                'type': type,
                'task': task,
                'required_developer': required_developer
            }

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {

                if (this.mainTab == 'project_wise') {
                    this.GetProjectList();
                }
                if (this.mainTab == 'task_wise') {
                    this.GetProjectDetail();
                }
                if (this.mainTab == 'user') {
                    this.GetUserDataArray();
                }
                if (this.mainTab == 'quotation') {
                    this.getQuotationList();
                }
            }
        });
    }


    assignDialog(): void {
        const dialogRef = this.alrt.open(CommonModalComponent, {
            width: '500px',
            panelClass: 'cs-modal',
            disableClose: true,
            data: {
                'encrpt_id': this.encrpt_id,
                'session': this.session,
                'from': 'projectList',
                'type': 'asignTaskMultiple',
                'assignId': this.assignId,
            }

        });
        dialogRef.afterClosed().subscribe(result => {

            if (result == true) {
                this.btnFlag = false;
                this.assignId = [];
                if (this.mainTab == 'project_wise') {
                    this.GetProjectList();
                }
                if (this.mainTab == 'task_wise') {
                    this.GetProjectDetail();
                }
                if (this.mainTab == 'user') {
                    this.GetUserDataArray();
                }
            }
        });
    }


    addContactPerson(id, type): void {
        const dialogRef = this.alrt.open(CommonModalComponent, {
            width: type == 'clientDeliveryDate' ? '500px' : type == 'updateProjectType' ? '500px' : '768px',
            panelClass: 'cs-modal',

            disableClose: true,
            data: {
                'from': 'projectList',
                'type': type,
                'project_id': id
            }

        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    getLogs(data, id): void {
        const dialogRef = this.alrt.open(CommonModalComponent, {
            panelClass: 'right-side',
            // panelClass: 'cs-modal',
            width: '1000px',
            disableClose: true,
            data: {
                'from': 'projectList',
                // 'from': 'projectListNew',
                'type': 'logs',
                'data': data,
            }

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {

                if (this.mainTab == 'project_wise') {
                    this.GetProjectList();
                }
                if (this.mainTab == 'task_wise') {
                    this.GetProjectDetail();
                }
                if (this.mainTab == 'user') {
                    this.GetUserDataArray();
                }
            }
        });
    }

    sessiondata: any = {};
    changeStatus(status) {
        this.dialog.alert('Confirmation!', 'Are you sure you want to verify the task?').then((result) => {
            if (result) {
                this.loader = true
                if (status == 'Closed') {
                    console.log("hello Closed");
                    this.db.FetchData({ 'task_id': this.TaskIdArr, 'session': this.sessiondata.value }, 'master/verify_multiple_task').subscribe((result: any) => {
                        this.loader = false;

                        this.dialog.success('Success', '');
                        this.TaskIdArr = []
                        this.GetProjectDetail();;
                    }, error => {
                        this.loader = false;

                        this.dialog.error('Something went wrong !!! Try Again...');
                    });
                }
                else {
                    this.db.FetchData({ 'task_id': this.TaskIdArr, 'session': this.sessiondata.value }, 'master/delivered_multiple_task')
                        .subscribe((result: any) => {
                            this.loader = false;

                            this.dialog.alert('Confirmation!', 'Are you sure you want to deliver the task?')
                            this.TaskIdArr = []
                            this.GetProjectDetail();;
                        }, error => {
                            this.loader = false;

                            this.dialog.error('Something went wrong !!! Try Again...');
                        });
                }
            }
            else {
                this.dialog.error('Something went wrong... Try Again');
            }
        });
    }

    canceltask(id: any) {
        this.task_id = id;
        this.dialog.delete('Task Data').then((result) => {
            if (result) {
                this.db.FetchData({ 'task_id': this.task_id, 'session': this.session.abacusdesk_user }, 'master/cancel_task')
                    .subscribe(res => {
                        this.dialog.success('Success', 'Task has been Canceled..!');
                        this.GetProjectDetail();;
                        this.TaskIdArr = [];
                    }, err => {
                        this.dialog.error('Something went wrong...Please try again');
                    });
            }
        });
    }

    edittask() {
        this.db.FetchData({ 'data': this.data, 'session': this.session.abacusdesk_user }, 'master/taskEdit')
            .subscribe(result => {
                if (result['status'] == 'Success') {
                    this.dialog.success('Task Edited', ' Successfully !!!');
                    var singleModalElem = document.querySelector('#edit_task');
                    this.GetProjectDetail();;
                    this.data = {};
                    this.loading = false;
                }
                else {
                    this.dialog.error('Something went wrong... Try Again !!!');
                }

            }, error => {
                this.dialog.error('Something went wrong... Try Again !!!');
                this.loading = false;
            });
    }

    departmentList: any = []
    getDepartment() {
        this.loading = true;

        this.db.FetchData({ 'session': this.session.abacusdesk_user }, 'master/getDepartment')
            .subscribe((result) => {
                if (result['statusCode'] == 200) {
                    this.departmentList = result['data']
                    this.loading = false;
                }
                else {
                    this.loading = false;
                    this.dialog.error(result['statusMessage']);
                }
            }, error => {
                this.loading = false;
                this.dialog.error('Something went wrong !!! Try Again...');
            });
    }



    closeTask(id, data, live_flag, type): void {
        console.log(id, data, live_flag, type);

        const dialogRef = this.alrt.open(TaskModalComponent, {
            width: '768px',
            panelClass: 'cs-modal',
            disableClose: true,
            data: {
                'model_type': type,
                'id': id,
                'taskData': data,
                'data': type == 'Draft Code Request' ? { live_flag: live_flag } : data
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                this.GetProjectDetail();
            }
        });
    }

    tabCount: any;
    getCount() {
        this.db.FetchData({'session': this.session.abacusdesk_user}, 'master/topCounter')
            .subscribe(res => {
                this.tabCount = res['data']
            }, err => {
                this.dialog.error('Something went wrong...Please try again');
            });

    }


    assignId: any = [];
    selectIds(event, index) {
        if (event.checked == true) {
            this.assignId.push(this.task[index])
            this.btnFlag = true;
        }
        else {
            let i = this.assignId.findIndex(row => row.id == this.task[index]['id']);
            if (i != -1) {
                this.assignId.splice(i, 1);
            }
        }
    }

    overallCounts: any = {};
    statusCounts: any = {};
    getQuotationList() {
        this.loader = true;

        if (this.pagenumber > this.total_page) {
            this.pagenumber = this.total_page;
            this.start = this.rqst_count - this.pagelimit;
        }
        if (this.start < 0) {
            this.start = 0;
        }
        this.db.FetchData({ 'start': this.start, 'pagelimit': this.pagelimit, 'session': this.session.abacusdesk_user, 'filter': this.filter, 'search': this.search }, 'master/getQuotationList')
            .subscribe((result: any) => {
                this.quotationListArray = result['result'];
                this.rqst_count = result['count'];
                this.overallCounts = result['overallCounts'];
                this.statusCounts = result['statusCounts'];


                this.loader = false;
                if (this.pagenumber > this.total_page) {
                    this.pagenumber = this.total_page;
                    this.start = this.rqst_count - this.pagelimit;
                }
                else {
                    this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
                }
                this.total_page = Math.ceil(this.rqst_count / this.pagelimit);
                this.sr_no = this.pagenumber - 1;
                this.sr_no = this.sr_no * this.pagelimit
                if (this.task.length) {
                    this.data_found = true;
                }
                else {
                    this.data_found = false;
                }

            }, error => {
                this.dialog.error('Something went wrong !!! Try again... ');
            });
    }
}