import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { data } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  // build command- ng build --prod

  // Live Link
  MyUrl: string = "https://connect.basiq360.com/api/";
  DbUrl: string = "https://connect.basiq360.com/api/index.php/";
  upload: string = "https://connect.basiq360.com/api/uploads/";
  

  // Test Link
  // MyUrl: string = "https://devcrm.abacusdesk.com/connectbasiq/api/";
  // DbUrl: string = "https://devcrm.abacusdesk.com/connectbasiq/api/index.php/";
  // upload: string = "https://devcrm.abacusdesk.com/connectbasiq/api/uploads/";

  can_active: string = '';
  filteredData: any = {};
  searchData: any = {}
  currentUserID: any;

  constructor(public http: HttpClient) { }
  header: any = new HttpHeaders();


  FetchData(request_data: any, fn: any) {
    this.header.append('Content-Type', 'application/json');
    return this.http.post(this.DbUrl + fn, JSON.stringify(request_data), { headers: this.header });
  }

  FileData(request_data: any, fn: any) {
    this.header.append('Content-Type', undefined);
    return this.http.post(this.DbUrl + fn, request_data, { headers: this.header });
  }


  setData(data) {
    this.filteredData = data;
  }

  getData() {
    return this.filteredData;
  }

  setSeacrhData(data) {
    this.searchData = data
  }

  getSearchData() {
    return this.searchData;
  }


}
