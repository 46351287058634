import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/DialogComponent';
import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';

import * as moment from 'moment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html'
})
export class ProjectAddComponent implements OnInit {

  project_form: any = {};
  departmentList: any = []
  salesUserList: any = []
  today: any = new Date()
  maxDate: any;
  projectStage = ['N/A', 'Planning', 'Development', 'Implementation', 'Live'];
  filter: any = {}
  TechnicalHeadList: any = [];

  constructor(public session: SessionService, public location: Location, public dialog: DialogComponent, public router: Router, public db: DatabaseService) {

    this.maxDate = new Date();

  }

  ngOnInit() {
    this.getDepartment()
    this.getSalesUser()


  }


  back(): void {
    this.location.back()
  }
  departmentIsSelected(event: any, data, type) {
    if (data == false && type == 'SFA') {
      this.project_form.sfa_sales_person_id = '';
      this.project_form.sfa_date = '';
    }
    if (data == false && type == 'DMS') {
      this.project_form.dms_sales_person_id = '';
      this.project_form.dms_date = '';
    }
    if (data == false && type == 'Loyalty') {
      this.project_form.loyalty_sales_person_id = '';
      this.project_form.loyalty_date = '';
    }
    if (data == false && type == 'Service') {
      this.project_form.service_sales_person_id = '';
      this.project_form.service_date = '';
    }
    if (data == false && type == 'Dispatch') {
      this.project_form.dispatch_sales_person_id = '';
      this.project_form.dispatch_date = '';
    }
  }

  formatAndAssignDate(field) {
    if (this.project_form[field]) {
      this.project_form[field] = moment(this.project_form[field]).format('YYYY-MM-DD');
    }
  }
  loading: any = '';
  submit_user(myform: any) {
 
    if(this.project_form.department == 'Platform'){
      this.project_form.department_id = 1;
    }
    if(this.project_form.department == 'Product'){
      this.project_form.department_id = 2;
    }
    this.loading = true;
    this.db.FetchData({ 'data': this.project_form, 'session': this.session.abacusdesk_user }, 'master/submit_project')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.loading = false;
          this.dialog.success('Project', 'Added Successfully !!!');
          this.router.navigate(['/project']);
        }
        else {
          this.loading = false;
          this.dialog.error(result['case']);

        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });

  }

  getDepartment() {
    this.loading = true;
    this.db.FetchData({ 'session': this.session.abacusdesk_user }, 'master/getDepartment')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.departmentList = result['data']
          this.loading = false;
        }
        else {
          this.loading = false;
          this.dialog.error(result['statusMessage']);
        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }
  getHeadList(th_name){
    this.filter.th_name = th_name
    this.db.FetchData({'filter':this.filter}, 'master/th_listing')
      .subscribe(result => {
        this.TechnicalHeadList = result['data'];
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }
  getSalesUser() {
    this.loading = true;

    this.db.FetchData({}, 'master/getSalesUser')
      .subscribe((result) => {
        this.salesUserList = result
        this.loading = false;
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }
  form2 = [];


}
