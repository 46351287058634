import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from './session.service';
import { DatabaseService } from './database.service';


@Injectable()
export class AuthGuard implements CanActivate {

    abacusdesk_user: any = [];

    constructor(public loc: Location, private router: Router, public session: SessionService, public db: DatabaseService) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


        this.session.GetSession().subscribe(
            data => {
                this.abacusdesk_user = data;
            },
            error => {
            });


        if (this.abacusdesk_user.ari_login) {
            this.db.can_active = '1';
            return true;
        }
        else {
            this.db.can_active = '';
            this.router.navigate([''], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }

}