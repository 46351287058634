import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/DialogComponent';
import { CommonModalComponent } from 'src/app/common-modal/common-modal.component';
import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-client-task-list',
  templateUrl: './client-task-list.component.html'
})
export class ClientTaskListComponent implements OnInit {
  search: any = {};
  total_page: any = ''
  pagenumber: any = ''
  start: any = 0;
  pagelimit: any = 200;
  loader: boolean = false;
  page_count: any = '';
  sr_no: any;
  taskList: any = [];
  count: any = '';
  filter: any = {};
  url: any = '';
  currentDate: any;
  lastPageData: any;
  @Input() dataToReceive: any;
  pt0: any;
  routes: any;
  status: any;
  hide: boolean = true
  overall: any = {};

  constructor(public alrt: MatDialog, public location: Location, public db: DatabaseService, public session: SessionService, public route: ActivatedRoute, public alert: DialogComponent, public dialog: DialogComponent) {
    this.search.status = 'Pending';
    this.url = this.db.upload + 'client_task_doc/';
    this.currentDate = new Date();
  }

  ngOnInit() {

    if (this.dataToReceive != undefined) {
      this.pt0 = this.dataToReceive.padding0;
      this.routes = this.dataToReceive.routes;
      this.hide = this.dataToReceive.hide;
      if (typeof this.dataToReceive.status === 'object' && Object.keys(this.dataToReceive.status).length === 0 || typeof this.dataToReceive.routes === 'object' && Object.keys(this.dataToReceive.routes).length === 0) {
        this.search.status = 'Pending'
      }
      else if (this.dataToReceive.routes == 'project-dashboard') {
        this.search.status = 'Pending'
      }
      else {
        this.search.status = this.dataToReceive.status
      }
    }
    this.route.params.subscribe(params => {
      this.lastPageData = this.route.queryParams['_value'];

      this.getTask();
    });
  }


  pervious() {
    this.start = this.start - this.pagelimit;
    this.getTask();
  }

  nextPage() {
    this.start = this.start + this.pagelimit;
    this.getTask();
  }



  date_format(type): void {
    if (type == 'date_created') {
      this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
    }
    if (type == 'estimated_time') {
      this.filter.estimated_time = moment(this.filter.estimated_time).format('YYYY-MM-DD');
    }
    if (type == 'completed_date') {
      this.filter.completed_date = moment(this.filter.completed_date).format('YYYY-MM-DD');
    }
    if (type == 'deliver_date') {
      this.filter.deliver_date = moment(this.filter.deliver_date).format('YYYY-MM-DD');
    }
    if (type == 'closed_date') {
      this.filter.closed_date = moment(this.filter.closed_date).format('YYYY-MM-DD');
    }
    this.getTask();
  }

  getTask() {
    this.loader = true;
    this.closeTaskId = [];

    if (this.pagenumber > this.total_page) {
      this.pagenumber = this.total_page;
      this.start = this.page_count - this.pagelimit;
    }
    if (this.start < 0) {
      this.start = 0;
    }

    let id

    if (this.lastPageData.id) {
      id = this.lastPageData.id
    }
    else if (this.dataToReceive != undefined) {
      if (this.dataToReceive.id) {
        id = this.dataToReceive.id;
      }
      else {
        id = ''
      }

    }
    else if (this.dataToReceive != undefined) {
      id = ''
    }
    else {
      id = this.session.abacusdesk_user.id
    }

    this.db.FetchData({ 'status': this.search.status, 'filter': this.filter, 'start': this.start, 'pagelimit': this.pagelimit, 'project_id': id, 'session': this.session.abacusdesk_user }, 'master/ClientTaskList').subscribe((result) => {
      this.page_count = result['count'];
      this.count = result['count'];
      this.taskList = result['task_list'];

      const statusMapping = {
        'Pending': 'pending',
        'Delivered': 'Delivered',
        'Hold': 'hold',
        'Trash': 'trash',
        'Reject': 'reject',
        'Inprocess': 'inprocess',
        'Complete': 'complete',
        'Closed': 'closed',
      };

      if (statusMapping.hasOwnProperty(this.search.status)) {
        this.page_count = result['count'][statusMapping[this.search.status]];
      }

      if (this.pagenumber > this.total_page) {
        this.pagenumber = this.total_page;
        this.start = this.page_count - this.pagelimit;
      }
      else {
        this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
      }
      this.total_page = Math.ceil(this.page_count / this.pagelimit);
      this.sr_no = this.pagenumber - 1;
      this.sr_no = this.sr_no * this.pagelimit
      this.loader = false;
      this.clientTaskDetails();
    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }

  openDialog(data, type, id): void {

    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: (type == 'clientDeliveryDate' || type == 'updateProjectType') ? '500px' : (type == 'task_and_hours_of_teams') ? '1200px' : '800px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'from': 'projectList',
        'type': type,
        'data': data,
        'project_id': id ? id : this.lastPageData.id,
        'project_routes': this.lastPageData.routes
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getTask();
      }
    });
  }


  taskAdd(id, sub_task_id, task_type, task): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: '768px',
      panelClass: 'cs-modal',
      // disableClose: true,
      data: {
        'from': 'projectList',
        'modalType': 'clientLogin',
        'project_id': id,
        'sub_task_id': sub_task_id,
        'task_type': task_type,
        'task_data': task,
        'type': 'addNewTask'
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getTask();
      }
    });
  }

  delete(id, type) {
    this.alert.alert('Alert', 'Are you sure you want to delete it ?').then((result) => {
      if (result) {
        this.db.FetchData({ 'id': id, 'type': type, 'session': this.session.abacusdesk_user }, 'master/client_delete_task')
          .subscribe(res => {
            this.dialog.success('Success', 'Task has been Canceled..!');
            this.getTask();;
          }, err => {
            this.dialog.error('Something went wrong...Please try again');
          });
      }
    });
  }


  refresh() {
    this.filter = {};
    this.closeTaskId = [];
    this.pagelimit = 50;
    this.start = 0;
    this.getTask();
  }


  goToImage(image) {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      panelClass: 'Image-modal',
      data: {
        'image': image,
        'type': 'imageModal'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  back(): void {
    this.location.back()
  }
  closeTaskId: any = [];
  GetId(event, index) {
    if (event.checked == true) {
      this.closeTaskId.push(this.taskList[index]['id'])
    }
    else {
      let i = this.closeTaskId.findIndex(row => row == this.taskList[index]['id']);
      if (i != -1) {
        this.closeTaskId.splice(i, 1);
      }

    }
  }



  changeStatus(status, text) {
    if (status == 'Inprocess' || (status == 'Complete' && text == 'complete')) {
      this.dialog.alert('Confirmation!', 'Are you sure you want to ' + text + ' the task?').then((result) => {
        if (result) {
          this.loader = true;

          let apiUrl

          if (this.search.status == 'Inprocess' && (this.session.abacusdesk_user.access_level == 2 || this.session.abacusdesk_user.access_level == 1)) {
            apiUrl = 'master/client_complete_task'
          }
          if (this.search.status == 'Complete' && (this.session.abacusdesk_user.access_level == 4 || this.session.abacusdesk_user.access_level == 1)) {
            apiUrl = 'master/client_deliver_task'
          }

          if (this.search.status == 'Delivered' && (this.session.abacusdesk_user.access_level == 'client' || this.session.abacusdesk_user.access_level == 1)) {
            apiUrl = 'master/client_close_task'
          }


          this.db.FetchData({ 'id': this.closeTaskId, 'session': this.session.abacusdesk_user }, apiUrl)
            .subscribe((result: any) => {
              if (result['msg'] == 'success') {
                this.loader = false;
                this.dialog.success('Success', '');
                this.getTask();
              }
              else {
                this.loader = false;
                this.dialog.error('Something went wrong !!! Try Again...');
              }
            }, error => {
              this.loader = false;
              this.dialog.error('Something went wrong !!! Try Again...');
            });
        }

      });
    }
    else {
      this.openModal()
    }
  }

  reopenTask(text) {
    this.dialog.alert('Confirmation!', 'Are you sure you want to ' + text + ' the task?').then((result) => {
      if (result) {
        this.loader = true;
        this.db.FetchData({ 'id': this.closeTaskId, 'session': this.session.abacusdesk_user }, 'master/client_reopen_task')
          .subscribe((result: any) => {
            if (result['msg'] == 'success') {
              this.loader = false;
              this.dialog.success('Success', '');
              this.getTask();
            }
            else {
              this.loader = false;
              this.dialog.error('Something went wrong !!! Try Again...');
            }
          }, error => {
            this.loader = false;
            this.dialog.error('Something went wrong !!! Try Again...');
          });
      }

    });
  }



  openModal(): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'id': this.closeTaskId,
        'session': this.session,
        'status': this.search.status,
        'from': 'projectList',
        'type': 'feedback',
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getTask();
      }
    });
  }


  getLogs(data, id): void {
    if (this.session.abacusdesk_user.access_level == 'client') {
      const dialogRef = this.alrt.open(CommonModalComponent, {
        panelClass: 'right-side',
        disableClose: true,
        data: {
          'from': 'projectList',
          'type': 'logs',
          'data': data.task_log,
          'task_id': id,
          'textarea': true,
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
        }
      });
    } else {
      const dialogRef = this.alrt.open(CommonModalComponent, {
        // panelClass: 'right-side',
        panelClass: 'cs-modal',
        width: '1000px',
        disableClose: true,
        data: {
          'from': 'projectList',
          'type': 'logsNew',
          'data': data,
          'task_id': id,
          'textarea': true,
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
        }
      });
    }


  }

  getLogs2(data, id): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      panelClass: 'right-side',
      disableClose: true,
      data: {
        'from': 'projectList',
        'type': 'logs',
        'data': data,
        'task_id': id,
        'textarea': true,
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
      }
    });
  }



  clientTaskDetails() {
    this.loader = true;
    this.db.FetchData({ 'session': this.session.abacusdesk_user, 'filter': this.filter }, 'master/clientTaskDetails').subscribe((result) => {
      console.log(result['overall_totals']);
      this.overall = result;

      this.loader = false;

    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }
}
