import { Component, OnInit } from '@angular/core';

import { DatabaseService } from 'src/app/database.service.js';
import { DialogComponent } from 'src/app/DialogComponent.js';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/session.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html'
})
export class AddUserComponent implements OnInit {

  user_form: any = {};
  options: any = {};
  role_list: any = [];

  departmentList: any = []

  jobTypeList: any = [];

  filter:any={}
  member_list:any=[]
  TechnicalHeadList:any=[]
  constructor(public db: DatabaseService, public location: Location, public dialog: DialogComponent, public router: Router, public session: SessionService) { }

  ngOnInit() {
    this.GetRole();
    this.getDepartment()
    this.GetJobType();
  }

  back(): void{
    this.location.back()
  }

  data: any = {};




  loading: any = '';
  submit_user(myform: any) {
    this.loading = true;
    if (this.user_form.department == 'Platform'){
      this.user_form.department_id = '1';
    }
    if (this.user_form.department == 'Product'){
      this.user_form.department_id = '2';
    }
    this.db.FetchData({ 'data': this.user_form, 'session': this.session.abacusdesk_user }, 'master/submit_user')
      .subscribe((result) => {
        if (result['msg'] == "success") {
          this.loading = false;
          this.dialog.success('User', 'Added !!!');
          this.router.navigate(['/project-list']);
        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });

  }


  mobile_msg: any = '';
  CheckMobileExist() {
    this.db.FetchData({ 'mobile': this.user_form.mobile_no }, 'master/check_mobile')
      .subscribe((result) => {
        if (result == 1) {
          this.mobile_msg = result;
        }
        else {
          this.mobile_msg = result;
        }
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  getHeadList(th_name){
    this.filter.th_name = th_name
    this.db.FetchData({'filter':this.filter}, 'master/th_listing')
      .subscribe(result => {
        this.TechnicalHeadList = result['data'];
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }


  getMemberList(department){
    this.filter.department = department
    this.db.FetchData({'filter':this.filter}, 'master/get_UserList')
      .subscribe(result => {
        this.member_list = result['user_list'];
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }



  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  GetRole() {
    this.db.FetchData({}, 'master/get_role')
      .subscribe(result => {
        this.role_list = result['role'];
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  GetJobType() {
    this.db.FetchData({}, 'master/getJobRoles')
      .subscribe(result => {
        this.jobTypeList = result['data'];
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }


  getDepartment() {
    this.loading = true;

    this.db.FetchData({ 'session': this.session.abacusdesk_user }, 'master/getDepartment')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.departmentList = result['data']
          this.loading = false;


        }
        else {
          this.loading = false;
          this.dialog.error(result['statusMessage']);
        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

}
