import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-point-rules',
  templateUrl: './point-rules.component.html',
  styleUrls: ['./point-rules.component.scss']
})
export class PointRulesComponent implements OnInit {
  activetab: any = 'prize';

  constructor(public location: Location) { }

  ngOnInit() {
  }


  back(): void {
    this.location.back()
  }

}
