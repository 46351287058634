import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { DatabaseService } from './database.service';

@Injectable()
export class AuthGuardLog implements CanActivate {

  abacusdesk_user: any = [];
  constructor(private router: Router, public session: SessionService, public db: DatabaseService) {
  }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    this.session.GetSession().subscribe(
      data => {
        this.abacusdesk_user = data;
      },
      error => {
      });


    if (this.abacusdesk_user.ari_login) {
      if (state.url != '/') {
        //this.router.navigate([state.url]);
      } else {
        this.router.navigate(['/project']);

      }
      this.db.can_active = '1';
      return false;

    } else {
      this.db.can_active = '';
      return true;
    }




  }

}

