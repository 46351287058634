import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { MatDialog } from '@angular/material';
import { SessionService } from '../session.service';
import { Location } from '@angular/common';
import { CommonModalComponent } from '../common-modal/common-modal.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  projectCount: any = [];
  projectList: any = [];
  teamData: any = [];
  teamProject: any = [];
  todayData: any = [];
  userData: any = [];
  countLoader: boolean = false;
  prListLoader: boolean = false;
  teamLoader: boolean = false;
  departmentLoader: boolean = false;
  todayLoader: boolean = false;
  taskLoader: boolean = false;
  taskSource: any;
  taskStatistics: any;
  teamTasks: any = [];
  sessiondata: any = {};
  filter = {
    department_name: ''
  };
    

  constructor(public db: DatabaseService, public location: Location, public alrt: MatDialog, public session: SessionService, public dialog: DialogComponent) { }

  ngOnInit() {
    this.sessiondata = this.session;

    this.getData();
    this.getProjectList();
    this.getTeamProject();
    this.getTeam();
    this.getTodayData();
    this.userAllData();
    this.taskPriority();
    console.log(this.sessiondata, 'sessiondata');

  }


  back(): void {
    this.location.back()
  }


  refreshTeamProject(){
    this.filter.department_name = ''
    this.getTeamProject();
  }

  getData() {
    this.countLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user }, 'Dashboard/projectCount')
      .subscribe(result => {
        this.projectCount = result['projects'];
        this.countLoader = false;

      }, error => {
        this.countLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }

  getProjectList() {
    this.prListLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user }, 'Dashboard/projectList')
      .subscribe(result => {
        this.projectList = result['total_company_project'];
        this.prListLoader = false;

      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
        this.prListLoader = false;
      });
  }

  getTeam() {
    this.departmentLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user }, 'Dashboard/teamData')
      .subscribe(result => {
        this.departmentLoader = false;
        this.teamData = result['total_team_data'];

        var data

        for (let i = 0; i < this.teamData.length; i++) {
          this.teamTasks.push({ "label": this.teamData[i].department, "value": this.teamData[i].pending_task });
        }

        this.taskStatistics = {
          "chart": {
            "theme": "fusion",
          },
          "data": this.teamTasks
        };


      }, error => {
        this.departmentLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }

  getTeamProject() {
    this.teamLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user,'filter':this.filter }, 'Dashboard/projectListTask')
      .subscribe(result => {
        this.teamProject = result['project'];
        this.teamLoader = false;
      }, error => {
        this.teamLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }

  getTodayData() {
    this.todayLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user ,'filter':this.filter}, 'Dashboard/userTodayData')
      .subscribe(result => {
        this.todayData = result['total_user_data']
        this.todayLoader = false;
      }, error => {
        this.todayLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }


  userAllData() {
    this.taskLoader = true;
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user }, 'Dashboard/userAllData')
      .subscribe(result => {
        this.userData = result['data'];
        this.taskLoader = false;
      }, error => {
        this.taskLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }

  taskPriority() {
    this.db.FetchData({ 'session': this.sessiondata.abacusdesk_user }, 'Dashboard/taskPriority')
      .subscribe(result => {
        this.taskSource = {
          "chart": {
            "theme": "fusion",
          },
          "data": [{ 'label': 'High', 'Value': result['data']['high'] }, { 'label': 'Normal', 'Value': result['data']['normal'] }, { 'label': 'Critical', 'Value': result['data']['critical'] },]
        };

      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
      });

  }





  taskOpen(id, project_id, type, task): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: type == 'userTask' ? '1024px' : '768px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'project_id': project_id,
        'id': id,
        'from': 'projectList',
        'pageType': 'dashbord',
        'type': type,
        'task': task,
      }

    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  toTitleCase(input: string): string {
    if (!input) return '';
    return input.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }


  openDialog(modaltype, id, department, type): void {
    const dialogRef = this.alrt.open(DashboardModalComponent, {
      width: '1024px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'model_type': modaltype,
        'type': type,
        'department': department,
        'id': id,
      }

    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
