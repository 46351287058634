import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-point-ledger',
  templateUrl: './point-ledger.component.html'
})
export class PointLedgerComponent implements OnInit {
  activetab: any = 'technicalHead';
  loader: boolean = false;
  lastPageData: any;
  data: any = []


  constructor(public location: Location, public route: ActivatedRoute, public db: DatabaseService, public dialog: DialogComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lastPageData = this.route.queryParams['_value'];
      this.db.currentUserID = this.lastPageData.user_id;
      this.getData();
    });
  }


  back(): void {
    this.location.back()
  }


  getData() {
    this.loader = true;
    this.db.FetchData({ 'user_id': this.lastPageData.user_id, }, "Rewards/getUserLedger").subscribe(result => {
      if (result['statusCode'] == 200) {
        this.loader = false;
        this.data = result['data']
      }
      else {
        this.loader = false
        this.dialog.error('Something went wrong... Try Again !');
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }

}

