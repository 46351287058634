import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FilterPipe } from './filter.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';


import { HeaderComponent } from './header/header.component';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxEditorModule } from 'ngx-editor';


import { LoginComponent } from './login/login.component';
import { DatabaseService } from './database.service';
import { SessionService } from './session.service';
import { AuthGuard } from './AuthGuard';
import { AuthGuardLog } from './AuthGuardLog';
import { DialogComponent } from './DialogComponent';

import { UserListComponent } from './user/user-list/user-list.component';
import { AddUserComponent } from './user/add-user/add-user.component';


import { ProjectListComponent } from './project/project-list/project-list.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { WorkingReportComponent } from './working-report/working-report.component';
import { ProjectAddComponent } from './project/project-add/project-add.component';
import { ProjectEditComponent } from './project/project-edit/project-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialModule } from './material';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { DashboardModalComponent } from './dashboard-modal/dashboard-modal.component';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { PointRulesComponent } from './point-rules/point-rules.component';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { ClientTaskListComponent } from './client-task/client-task-list/client-task-list.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { PointLedgerComponent } from './point-ledger/point-ledger.component';
import { LeaveComponent } from './leave/leave.component';
import { CalenderComponent } from './calender/calender.component';
import { CodeLiveComponent } from './code-live/code-live.component';

import { QuotationAddEditComponent } from './quotation/quotation-add-edit/quotation-add-edit.component';
import { QuotationDetailComponent } from './quotation/quotation-detail/quotation-detail.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { ReportsComponent } from './reports/reports.component';


// import { MatButtonModule } from '@angular/material';


// import * as bootstrap from "bootstrap";
// import * as $ from "jquery";
const routes: Routes = [
  // canActivate: [AuthGuardLog] 
  { path: '', component: LoginComponent, },
  { path: 'task-feedback/:status/:mobile/:id', component: WhatsappComponent },

  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },

  { path: 'user', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'adduser', component: AddUserComponent, canActivate: [AuthGuard] },
  { path: 'add-project', component: ProjectAddComponent, canActivate: [AuthGuard] },
  { path: 'edit-project/:id', component: ProjectEditComponent, canActivate: [AuthGuard] },
  { path: 'project', component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: 'task', component: TaskListComponent, canActivate: [AuthGuard] },
  { path: 'workingreport/:id', component: WorkingReportComponent, canActivate: [AuthGuard] },
  { path: 'rules', component: PointRulesComponent, canActivate: [AuthGuard] },
  { path: 'project-dashboard', component: ProjectDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'task-support', component: ClientTaskListComponent, canActivate: [AuthGuard] },
  { path: 'project-task-detail', component: ClientTaskListComponent, canActivate: [AuthGuard] },
  { path: 'point-ledger/:id', component: PointLedgerComponent, canActivate: [AuthGuard] },
  { path: 'leave/:id', component: LeaveComponent, canActivate: [AuthGuard] },
  { path: 'task-estimation/:id', component: CalenderComponent, canActivate: [AuthGuard] },
  { path: 'add-quotation', component: QuotationAddEditComponent, canActivate: [AuthGuard] },
  { path: 'quotation-detail/:id', component: QuotationDetailComponent, canActivate: [AuthGuard] },
  { path: 'reports/:type', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' },
];




FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    FilterPipe,
    UserListComponent,
    AddUserComponent,
    SafeUrlPipe,
    ProjectListComponent,
    TaskListComponent,
    WorkingReportComponent,
    ProjectAddComponent,
    ProjectEditComponent,
    DashboardComponent,
    DashboardModalComponent,
    CommonModalComponent,
    TaskModalComponent,
    PointRulesComponent,
    ProjectDashboardComponent,
    ClientTaskListComponent,
    PointLedgerComponent,
    LeaveComponent,
    CalenderComponent,
    CodeLiveComponent,
    QuotationAddEditComponent,
    QuotationDetailComponent,
    WhatsappComponent,
    ReportsComponent,
   
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    // MatButtonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    AutocompleteLibModule,
    NgxEditorModule,
    FusionChartsModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    SafeUrlPipe,
    MaterialModule,
  ],
  providers: [
    DatabaseService,
    SessionService,
    AuthGuard,
    AuthGuardLog,
    DialogComponent,
    NgxNavigationWithDataComponent,
    BnNgIdleService,
  ],
  entryComponents: [
    DashboardModalComponent,
    CommonModalComponent,
    TaskModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
