import { OnInit, Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })


export class DialogComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

    confirmation(msg: any) {
        return Swal({
            title: 'Are you sure?',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                return true;
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal(
                    'Cancelled',
                    'Your data is not modfied :)',
                    'error'
                )
                return false;
            }
        })
    }


    delete(msg: any) {
        return Swal({
            title: 'Are you sure?',
            text: 'You want to delete ' + msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                return true;
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal(
                    'Cancelled',
                    'Your ' + msg + ' is safe &#x263A;',
                    'error'
                )
                return false;

            }
        })
    }

    success(title: any, msg: any) {
        Swal(
            title + '!',
            msg + '.',
            'success'
        )
        return true;
    }

    error(msg: any) {
        Swal({
            type: 'error',
            title: 'Oops...',
            text: msg,
            // footer: '<a href>Why do I have this issue?</a>'
        })
    }

    completeTask(msg: any) {
        return Swal({
            title: 'Completed?',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                return true;
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal(
                    'Cancelled',
                    'Your data is not modified, :)',
                    'error'
                )
                return false;
            }
        })
    }


    alert(title: any, msg: any) {
        return Swal({
            title: title,
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                return true;
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal(
                    'Cancelled',
                    'Your data is not modified, :)',
                    'error'
                )
                return false;
            }
        })
    }

    commanAlert(msg: any) {
        return Swal({
            title: 'Alert',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                return true;
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal(
                    'Cancelled',
                    'Your data is not modified, :)',
                    'error'
                )
                return false;
            }
        })
    }

}