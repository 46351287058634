import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/database.service';
import { DialogComponent } from 'src/app/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-quotation-add-edit',
  templateUrl: './quotation-add-edit.component.html',
  styleUrls: ['./quotation-add-edit.component.scss']
})
export class QuotationAddEditComponent implements OnInit {
  project_data: any = {};
  savingFlag: boolean = false;
  projectList: any = [];
  taskList: any = [];
  QtyCount: any = 0;
  addToListButton: boolean = true;
  TaskArray: any = [];
  quotation_id: any = null;
  quotationDetail: any = {};
  constructor(public db: DatabaseService, public session: SessionService, public dialog: DialogComponent, public location: Location, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((param) => {
      this.quotation_id = param.quotation_id
      console.log(this.quotation_id);

    });
    this.project_data.task_details = [];
    this.projectDetails();
    if (this.quotation_id != null || this.quotation_id != undefined) {
      this.project_data.quotation_id = this.quotation_id
      setTimeout(() => {
        this.getQuotationDetail();

      }, 600);
    }
  }

  back() {
    this.location.back();
  }

  taskDetails(search: any = '') {
    this.project_data.task_details = [];

    this.db.FetchData({ 'session': this.session.abacusdesk_user, 'projectDetails': this.project_data.project_details, search, 'quotation_id': this.quotation_id }, 'master/getProjectTaskList')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.taskList = result['projects'];
        }
        else {

          this.dialog.error(result['statusMessage']);
        }
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  projectDetails(search: any = '') {
    this.db.FetchData({ 'session': this.session.abacusdesk_user, search }, 'master/getProjectList')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.projectList = result['projects'];
        }
        else {

          this.dialog.error(result['statusMessage']);
        }
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  deleteQuotation(index) {
    this.dialog.delete("Quotation ?").then((result) => {
      if (result) {
        this.project_data.task_details.splice(index, 1);

      }
    })
  }


  disableAddToList() {

    this.QtyCount = 0;
    for (let i = 0; i < this.project_data.task_details.length; i++) {

      if (this.project_data.task_details[i].total_working_hours != undefined && this.project_data.task_details[i].total_working_hours > 0 && (this.project_data.task_details[i].total_working_days != undefined && this.project_data.task_details[i].total_working_days > 0)) {
        this.QtyCount++;

      } else {

        this.QtyCount--;
        this.addToListButton = true;
      }

      if (this.project_data.task_details.length == this.QtyCount) {
        this.addToListButton = false;

      }
    }
  }

  calculateDays(data) {
    data.total_working_days = Math.ceil(Number(data.total_working_hours) / 6);

  }

  add_Task_List() {


    this.project_data.overall_working_hours = 0;
    this.project_data.overall_working_days = 0;
    for (let i = 0; i < this.project_data.task_details.length; i++) {
      let index = this.TaskArray.findIndex(r => r.task == this.project_data.task_details[i].task);

      if (index != -1) {
        this.TaskArray[index]['total_working_hours'] = this.project_data.task_details[i]['total_working_hours'];
        this.TaskArray[index]['total_working_days'] = this.project_data.task_details[i]['total_working_days'];
      } else {
        this.TaskArray.push(this.project_data.task_details[i]);
      }

    }
    this.project_data.overall_working_hours = this.TaskArray.reduce((prev, current) => prev + Number(current.total_working_hours), 0);
    // this.project_data.overall_working_days = this.TaskArray.reduce((prev, current) => prev + Number(current.total_working_days), 0);
    this.project_data.overall_working_days = Math.ceil(this.project_data.overall_working_hours / 6);
     this.project_data.total_working_amount = this.project_data.overall_working_days * this.project_data.daily_working_cost;
    this.project_data.task_details = [];
    this.addToListButton = true;
  }


  deleteQuotation2(index) {
    this.dialog.delete("Quotation ?").then((result) => {
      if (result) {
        this.TaskArray.splice(index, 1);
        this.project_data.overall_working_hours = this.TaskArray.reduce((prev, current) => prev + Number(current.total_working_hours), 0);
        // this.project_data.overall_working_days = this.TaskArray.reduce((prev, current) => prev + Number(current.total_working_days), 0);
        this.project_data.overall_working_days = Math.ceil(this.project_data.overall_working_hours / 6);

        this.project_data.total_working_amount = this.project_data.overall_working_days * this.project_data.daily_working_cost;
        this.project_data.task_details.splice(index, 1);
      }
    })
  }

  submitQuotation() {
    this.savingFlag = true;
    let header = this.quotation_id != null || this.quotation_id != undefined ? 'master/updateQuotation' : 'master/submitQuotation';
    this.db.FetchData({ 'session': this.session.abacusdesk_user, 'projectData': this.project_data, 'quotationItems': this.TaskArray }, header)
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.savingFlag = false;
          this.dialog.success("Quotation Added", result['statusMsg']);
          this.router.navigate(['/project'], { queryParams: { "mainTab": "quotation" } });
        }
        else {
          this.savingFlag = false;
          this.dialog.error(result['statusMsg']);
        }
      }, error => {
        this.savingFlag = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  getQuotationDetail() {

    this.db.FetchData({ 'quotation_id': this.quotation_id }, 'master/getQuotationDetail').subscribe((resp) => {

      this.quotationDetail = resp['result'];

      this.project_data.project_details = this.projectList.find(r => r.id == this.quotationDetail.project_id);
      if (this.project_data.project_details) this.taskDetails();
      this.project_data.daily_working_cost = this.quotationDetail.daily_working_cost;
      this.project_data.overall_working_hours = this.quotationDetail.overall_working_hours;
      this.project_data.overall_working_days = this.quotationDetail.overall_working_days;
      this.project_data.total_working_amount = this.quotationDetail.total_working_amount;
      this.TaskArray = this.quotationDetail.item;

    })
  }




}
