import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html'
})
export class LeaveComponent implements OnInit {
  activetab: any = 'technicalHead';
  loader: boolean = false;
  lastPageData: any;
  today: any
  data: any = []
  userdata: any;
  tabType: any = 'My'

  constructor(public location: Location, public userSession: SessionService, public alrt: MatDialog, public route: ActivatedRoute, public db: DatabaseService, public dialog: DialogComponent) {
    this.today = new Date();
    this.userdata = userSession;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lastPageData = this.route.queryParams['_value'];
      this.getData();
    });
  }


  back(): void {
    this.location.back()
  }


  getData() {
    this.loader = true;
    this.db.FetchData({ 'user_id': this.lastPageData.user_id, 'mode': this.tabType, 'session': this.userSession.abacusdesk_user }, "Master/leaveList").subscribe(result => {
      if (result['statusCode'] == 200) {
        this.loader = false;
        this.data = result['data']
      }
      else {
        this.loader = false
        this.dialog.error('Something went wrong... Try Again !');
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }


  openDialog(): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'from': 'projectList',
        'type': 'leave',
        'user_id': this.lastPageData.user_id,
        'user_name': this.lastPageData.user_name
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getData();
      }
    });
  }



  cancelLeave(id) {
    this.dialog.commanAlert('Are you sure you want to cancel this leave ?').then((result) => {
      if (result) {
        this.loader = true;
        this.db.FetchData({ 'id': id }, 'Master/changeLeaveStatus')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              this.dialog.success('Status Changed', result['statusMsg']);
              this.loader = false;
              this.getData();
            }
            else {
              this.loader = false;
              this.dialog.error(result['statusMsg']);
            }

          }, error => {
            this.loader = false;
            this.dialog.error('Something went wrong... Try Again !!!');
          });
      }
    });
  }

}


