import { Component, Input, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/DialogComponent';
import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { TaskModalComponent } from 'src/app/task-modal/task-modal.component';


@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html'
})
export class TaskListComponent implements OnInit {

  options: any = {};
  active_tab: any = 'Draft';
  task_count: any = [];
  task_list: any = [];
  data: any = {};
  sessiondata: any = {};
  today = new Date();
  timeValidation: any
  url: any;
  item: any = [];
  @Input() dataToReceive: any;
  hide: boolean = true;
  pt0: any;
  routes: any;
  page_count: any = '';
  lastPageData: any;
  sr_no: any;
  count: any = '';
  taskList
  overall: any = {};


  constructor(public dialog: DialogComponent, public alrt: MatDialog, public db: DatabaseService, public session: SessionService) {
    this.url = this.db.upload + 'client_task_doc/';
    this.active_tab = 'Draft'
    this.sessiondata = this.session.GetSession();




    this.timeValidation = new Date('2023-01-01T10:30:00');
    setInterval(() => {
      this.today = new Date();
    }, 1000);
  }

  ngOnInit() {
    if (this.dataToReceive != undefined) {
      this.pt0 = this.dataToReceive.padding0;
      this.hide = this.dataToReceive.hide;
      if (typeof this.dataToReceive.status === 'object' && Object.keys(this.dataToReceive.status).length === 0 || typeof this.dataToReceive.routes === 'object' && Object.keys(this.dataToReceive.routes).length === 0) {
        this.search.status = this.dataToReceive.status;
        this.getCodeLive();
        this.getTask();
      }
      else {
        this.search.status = this.dataToReceive.status;
        this.getCodeLive();
        this.getTask();
      }

    }
    else {
      if (this.sessiondata.value.access_level == 7) {
        this.search.status = 'CodeLive';
      }
      else {
        this.search.status = 'client_task';
        // this.GetTaskList();
        this.getTask();
      }
    }






  }


  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  Quantity(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  pagenumber: any = '';

  search_status(status) {
    this.search.status = status;
  }

  loader: boolean = false;
  progress: any = '';
  start: any = '';
  pagelimit: any = '';
  search: any = {};
  request_count: any = '';
  total_page: any = '';
  data_found: any = '';

  GetTaskList(pagelimit: any = 100, start: any = 0, action: any = '') {
    this.loader = true;
    this.progress = "1";
    this.pagelimit = pagelimit;
    this.start = start;

    if (action == 'refresh') {
      this.start = 0;
    }

    this.db.FetchData({ 'search': this.search, 'start': this.start, 'filter': this.filter, 'pagelimit': this.pagelimit, 'login_data': this.sessiondata.value }, 'master/GetTaskList')
      .subscribe((result: any) => {
        this.task_list = result['task_list'];
        this.task_count = result['task_count'];
        this.request_count = result['count'];
        this.total_page = Math.ceil(this.request_count / this.pagelimit);
        this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
        this.loader = false;
        this.progress = '';

        if (this.task_list.length != 0) { this.data_found = true; }
        else { this.data_found = false; }


        this.task_list.map((item) => {
          item.check = false;
        });

      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });


  }




  delete_task(index: any, id: any) {
    this.dialog.delete('Task Data').then((result) => {
      if (result) {
        this.db.FetchData({ 'id': id, 'session': this.sessiondata.value }, 'master/delete_task')
          .subscribe(res => {
            this.task_list.splice(index, 1);
            this.dialog.success('Success', 'Task Data has been deleted..!');
          }, err => {
            this.dialog.error('Something went wrong...Please try again');
          });
      }
    });
  }


  delete(id) {
    this.dialog.delete('').then((result) => {
      if (result) {
        this.db.FetchData({ 'id': id, 'session': this.sessiondata.value }, 'master/deleteDraftApi')
          .subscribe(res => {
            if (res['statusCode'] == 200) {
              this.dialog.success('Success', 'Task Data has been deleted..!');
              this.getCodeLive();
            }
            else {
              this.dialog.error('Something went wrong...Please try again');
            }
          }, err => {
            this.dialog.error('Something went wrong...Please try again');
          });
      }
    });
  }


  closeTaskId: any = [];
  GetId(event, index, id) {
    const isChecked = event.checked;
    const itemId = this.item[index].id;
    console.log(this.item[index]);


    if (isChecked) {
      this.closeTaskId.push(this.item[index]);

    } else {
      const i = this.closeTaskId.findIndex(row => row.id === itemId);
      if (i !== -1) {
        this.closeTaskId.splice(i, 1);
      }
    }

    this.item.forEach(item => {
      item.disabled = id !== item.project_id;
    });

    if (this.closeTaskId.length === 0) {
      this.item.forEach(item => {
        item.disabled = '';
      });
    }


    console.log(this.closeTaskId, 'closeTaskId.length');
    console.log(this.closeTaskId.length, 'closeTaskId.length');
    console.log(this.item.length, 'item.length');
    console.log(this.search.status, 'search.status');
    console.log(this.active_tab, 'active_tab');
    console.log(this.closeTaskId.length && this.item.length && this.search.status == 'CodeLiveDraft' && this.active_tab == 'Draft', 'conditions');




  }


  form: any = {};
  get_task_id(task_id: any, project_id: any) {
    this.form.task_id = task_id;
    this.form.project_id = project_id;
  }

  document: any = [];
  get_doc_data(task_id: any) {
    this.document = this.task_list.filter(x => x.id === task_id)[0].task_document;
    this.document = this.document[0];
  }




  urls = new Array<string>();
  formData = new FormData();





  i: any = 0;

  ItemArr: any = [];
  GetTaskListArray(event, index: any, action: any = '') {
    if (action == 'all') {
      if (event.checked == true) {
        this.task_list.map((item) => {
          item.check = true;
        });
      }
      else {
        this.task_list.map((item) => {
          item.check = false;
        });


      }


    } else {

      this.task_list[index].check = !this.task_list[index].check;
    }



    this.ItemArr = this.task_list.reduce((results, item) => {
      if (item.check == true) results.push(item.id);
      return results;
    }, []);
  }




  loading: any = '';


  complete_task() {
    this.dialog.completeTask('Have you tested it well..? ').then((result) => {
      if (result) {
        this.loader = true;
        this.progress = "1";
        this.db.FetchData({ 'task_id': this.ItemArr, 'count': this.ItemArr.length, 'session': this.sessiondata.value }, 'master/close_multiple_task')
          .subscribe((result: any) => {
            this.loader = false;
            this.progress = '';
            this.dialog.success('Success', result.quotes);
            this.ItemArr = []
            this.GetTaskList('', '', '')
          }, error => {
            this.loader = false;
            this.progress = '';
            this.dialog.error('Something went wrong !!! Try Again...');
          });
      }
      else {
        // this.dialog.error('Something went wrong... Try Again');
      }
    });
  }



  openDialog(id, data, type): void {
    console.log(data,'data live flag')
    const dialogRef = this.alrt.open(TaskModalComponent, {
      width: data.live_flag == 1 || type == 'Draft Code Request' || type == 'Project Details' ? '768px' : '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'model_type': type,
        'id': id,
        'taskData': data,
        'data': type == 'Draft Code Request' ? { live_flag: 1 } : data
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if (this.search.status == 'CodeLiveDraft') {
          this.getCodeLive();
        }
        else {
          this.GetTaskList();
        }
      }
    });
  }

  filter: any = {}
  date_format(type): void {
    if (type == 'date_created') {
      this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
      if (this.search.status == 'CodeLiveDraft') {
        this.getCodeLive();
      }
      else {
        this.GetTaskList();
      }
    }
    if (type == 'assignedDate') {
      this.filter.assignedDate = moment(this.filter.assignedDate).format('YYYY-MM-DD');
      if (this.search.status == 'CodeLiveDraft') {
        this.getCodeLive();
      }
      else {
        this.GetTaskList();
      }
    }
    if (type == 'completed_date') {
      this.filter.completed_date = moment(this.filter.completed_date).format('YYYY-MM-DD');
      if (this.search.status == 'CodeLiveDraft') {
        this.getCodeLive();
      }
      else {
        this.GetTaskList();
      }
    }
  }
  tabcount: any = {};
  getCodeLive(pagelimit: any = 20, start: any = 0, action: any = '') {
    this.closeTaskId = [];
    this.loader = true;
    this.progress = "1";
    this.pagelimit = pagelimit;
    this.start = start;

    if (action == 'refresh') {
      this.start = 0;
    }

    this.db.FetchData({ 'search': this.search, 'active_tab': this.active_tab, 'filter': this.filter, 'login_data': this.sessiondata.value }, 'master/taskApiList')
      .subscribe((result: any) => {
        this.item = result['data'];
        this.tabcount = result['count'];
        this.total_page = Math.ceil(this.request_count / this.pagelimit);
        this.loader = false;
        this.progress = '';

        if (this.task_list.length != 0) { this.data_found = true; }
        else { this.data_found = false; }


        this.task_list.map((item) => {
          item.check = false;
        });

      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });


  }


  getTask() {
    this.loader = true;
    this.closeTaskId = [];
    this.db.FetchData({ 'status': this.search.status, 'filter': this.filter, 'start': this.start, 'pagelimit': this.pagelimit, 'session': this.session.abacusdesk_user }, 'master/ClientTaskList').subscribe((result) => {
      this.page_count = result['count'];
      this.count = result['count'];
      this.taskList = result['task_list'];

      const statusMapping = {
        'Pending': 'pending',
        'Delivered': 'Delivered',
        'Hold': 'hold',
        'Trash': 'trash',
        'Reject': 'reject',
        'Inprocess': 'inprocess',
        'Complete': 'complete',
        'Closed': 'closed',
      };

      if (statusMapping.hasOwnProperty(this.search.status)) {
        this.page_count = result['count'][statusMapping[this.search.status]];
      }

      if (this.pagenumber > this.total_page) {
        this.pagenumber = this.total_page;
        this.start = this.page_count - this.pagelimit;
      }
      else {
        this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
      }
      this.total_page = Math.ceil(this.page_count / this.pagelimit);
      this.sr_no = this.pagenumber - 1;
      this.sr_no = this.sr_no * this.pagelimit
      this.loader = false;
      this.clientTaskDetails();
    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }

  clientTaskDetails() {
    this.loader = true;
    this.db.FetchData({ 'session': this.session.abacusdesk_user, 'filter': this.filter }, 'master/clientTaskDetails').subscribe((result) => {
      console.log(result['overall_totals']);
      this.overall = result;

      this.loader = false;

    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }

  refresh() {
    if (this.search.status == 'CodeLiveDraft') {
      this.filter = {};
      this.getCodeLive()
    }
    else {
      this.filter = {};
      this.GetTaskList()
    }
  }




  openDialog1(type): void {
    const dialogRef = this.alrt.open(TaskModalComponent, {
      width: '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'model_type': type,
        'data': this.closeTaskId
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.closeTaskId = [];
        this.getCodeLive();
      }
    });
  }


  pervious() {
    this.start = this.start - this.pagelimit;
    this.GetTaskList(100, this.start);
  }

  nextPage() {
    this.start = this.start + this.pagelimit;
    this.GetTaskList(100, this.start);
  }

  filterSearch() {
    if (this.search.status == 'CodeLiveDraft') {
      this.getCodeLive()
    }
    else {
      this.GetTaskList()
    }
  }


}
