import { Component, OnInit, Renderer2 } from '@angular/core';
import { SessionService } from '../session.service';
import { DatabaseService } from '../database.service.js';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DialogComponent } from '../DialogComponent.js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  options = {};
  normalHeader: any = true;

  constructor(private renderer: Renderer2, public session: SessionService, public dialog: DialogComponent, public db: DatabaseService, public router: Router, public navCtrl: NgxNavigationWithDataComponent, private activatedRoute: ActivatedRoute) {
    var route = this.activatedRoute.snapshot.firstChild.url[0].path
    if (route == 'projecttaskdetail') {
      this.normalHeader = false;
    }
    else {
      this.normalHeader = true;
    }

    if (session.abacusdesk_user.access_level != 'client' && this.session.abacusdesk_user.access_level != '') {
      this.getTaskThaughts();
    }
  }


  ngOnInit() {
    if (this.session.abacusdesk_user.access_level != 'client' && this.session.abacusdesk_user != '') {
      setInterval(() => {
        if (this.session.abacusdesk_user != '' && this.session.abacusdesk_user.access_level != 'client') {
          this.db.FetchData({}, 'master/taskThaughts')
            .subscribe(res => {
              this.getTaskThaughts();
            },
              (error) => {
              }
            );
        }

      }, 30000);
    }
  }

  status: boolean = false;
  toggleHeader() {
    this.status = !this.status;
    if (this.status) {
      this.renderer.addClass(document.body, 'active');
    }
    else {
      this.renderer.removeClass(document.body, 'active');
    }
  }


  logout() {
    this.session.LogOutSession();
    this.router.navigate(['']);
  }

  todayThaught = [];
  getTaskThaughts() {
    this.db.FetchData({}, 'master/taskThaughts')
      .subscribe(res => {
        this.todayThaught = res['msg']
      }, err => {
        this.dialog.error('Something went wrong...Please try again');
      });

  }

 

}
