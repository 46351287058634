import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './database.service';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { SessionService } from './session.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CRM Support :: Basiq360';
  user: any;
  constructor(public db: DatabaseService, private router: Router, public session: SessionService, public route: Router, private bnIdle: BnNgIdleService) { }

  ngOnInit() {
    window.onload = (event) => {
      document.getElementById("splashScreen").classList.add("splashScreenFadeHide")
    }
    document.getElementById("splashScreen").addEventListener('transitionend', (e) => {
      document.getElementById("splashScreen").style.display = 'none'
    });


    this.bnIdle.startWatching(7200).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.session.LogOutSession();
        this.router.navigate(['']);
        this.user = JSON.parse(localStorage.getItem('st_user')) || [];
        this.user = {};
        this.user.st_log = false;
        localStorage.removeItem('st_user');
      }

    });

  }

}
