import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../DialogComponent';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  formData: any = {};
  savingFlag: boolean = false;
  star: any = 0;
  start_rating: any = {};
  URL: any = '';
  id: any;
  clientStatus: boolean = false;
  ratingCheck: boolean = false;

  constructor(public service: DatabaseService, public rout: Router, public route: ActivatedRoute, public dialog: DialogComponent,) {
    // window.location.reload();

    this.route.params.subscribe(params => {
      console.log(params);

      this.formData.id = params.id;
      this.formData.mobile = params.mobile;
      this.formData.task_status = params.status;

    });

  }

  ngOnInit() {
  }
  Submit() {
    if (this.formData.task_status == 'Complete' && this.star == 0) {
      this.ratingCheck = true;
      // alert(';rating required');
      this.dialog.error("Rating Rrquired 😊. Please Rate Above 3");
      return
    };
    this.savingFlag = true;
    this.service.FetchData({ 'data': this.formData }, 'master/clientActionOnTaskThroughWhatsapp').subscribe((result => {
      if (result['statusCode'] == 200) {
        this.dialog.success("", result['statusMsg']);
        this.clientStatus = true;
        // window.location.href='www.google.com';
        // window.close();
        // this.rout.navigate(['/'])
      }
      else {
        this.dialog.error(result['statusMsg']);
        this.savingFlag = false;
        this.clientStatus = true;
      }

    }))
  }


  rating(star) {
    this.ratingCheck = false;
    this.star = star;
    this.formData.rating = this.star;
  }
}
