import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/DialogComponent';
import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';
import { Location } from '@angular/common';


import * as moment from 'moment';
@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent implements OnInit {
  departmentList: any = []
  today: any = new Date()
  salesUserList: any = []
  selectedDate: any = '';
  projectStage = ['N/A', 'Planning', 'Development', 'Implementation', 'Live'];
  date: any = {
    format: 'dd mmm yyyy',
    autoClose: "true",
  };

  maxDate: any;
  constructor(public db: DatabaseService, public location: Location, public dialog: DialogComponent, public session: SessionService, public router: Router, public route: ActivatedRoute) {
    this.getDepartment();
    this.route.params.subscribe(params => {
      this.encrpt_id = params.id;
      this.GetProjectDetail('20', '0', '', 'Pending');
    });
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.getSalesUser();
  }
  project_form: any = {};
  loader: any = '';
  progress: any = '';
  encrpt_id: any = '';
  start: any = "0";
  pagelimit: any = "100";
  search: any = {};


  GetProjectDetail(pagelimit: any = 20, start: any = 0, action: any = '', status: any = 'Pending') {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    this.search.status = status;

    this.db.FetchData({ 'search': this.search, 'start': this.start, 'pagelimit': this.pagelimit, 'id': this.encrpt_id, 'session': this.session.abacusdesk_user }, 'master/get_project_detail')
      .subscribe((result: any) => {
        this.project_form = result['project'];
        this.loader = '';
        this.progress = '';

        const dateFields = [
          'sfa_live_date',
          'dms_live_date',
          'loyalty_live_date',
          'service_live_date',
          'dispatch_live_date',
        ];

        for (const field of dateFields) {
          if (this.project_form[field] === '0000-00-00') {
            this.project_form[field] = '';
          }
        }


        const dateField = [
          'sfa_date',
          'dms_date',
          'loyalty_date',
          'service_date',
          'dispatch_date',
        ];

        for (const field of dateField) {
          if (this.project_form[field] === '0000-00-00') {
            this.project_form[field] = '';
          }
        }

        if (this.project_form.sfa == 1) {
          this.project_form.sfa = true
        } else {
          this.project_form.sfa = false

        }
        if (this.project_form.loyalty == 1) {
          this.project_form.loyalty = true
        } else {
          this.project_form.loyalty = false

        }
        if (this.project_form.dms == 1) {
          this.project_form.dms = true
        } else {
          this.project_form.dms = false

        }
        if (this.project_form.service == 1) {
          this.project_form.service = true
        } else {
          this.project_form.service = false

        }
        if (this.project_form.dispatch == 1) {
          this.project_form.dispatch = true
        } else {
          this.project_form.dispatch = false

        }
      }, error => {
        this.dialog.error('Something went wrong !!! Try again... ');
      });
  }

  deleteDepatments(index: any) {


    this.dialog.delete('Department').then((result) => {
      if (result) {
        this.Departments.splice(index, 1);
        delete this.departmentList[index].assigned_date;
        delete this.departmentList[index].checked;
        this.selectedDate = ''
      }
      else {
        this.dialog.error('Something went wrong... Try Again');
      }
    });
  }
  Departments: any = []
  selectedDates: any = []
  setDepartmentName(event: any, i, data) {

    if (this.selectedDate) {
      let Index = this.Departments.findIndex(row => row.department == data)
      if (Index != -1) {
        this.departmentList[Index].assigned_date = moment(this.selectedDate).format('DD-MM-YYYY');
        this.Departments[Index].assigned_date = moment(this.selectedDate).format('DD-MM-YYYY');
      } else {
        this.Departments.push({ 'department': data, 'checked': 'true', 'assigned_date': moment(this.selectedDate).format('DD-MM-YYYY') })
        this.departmentList[i].checked = "true";
        this.departmentList[i].assigned_date = moment(this.selectedDate).format('DD-MM-YYYY');
      }
    } else {
      this.Departments = this.Departments.filter((department) => department !== data);
      this.departmentList[i].checked = "false";


    }

  }
  getSalesUser() {
    this.loading = true;

    this.db.FetchData({}, 'master/getSalesUser')
      .subscribe((result) => {
        this.salesUserList = result
        this.loading = false;



      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }
  form2 = [];
  getselectionvalue(data) {
    this.form2 = data.target.M_FormSelect.$selectOptions.filter(x => x.selected == true);
  }

  loading: any = '';
  formatAndAssignDate(field) {
    if (this.project_form[field]) {
      this.project_form[field] = moment(this.project_form[field]).format('YYYY-MM-DD');
    }
  }
  getDepartment() {
    this.loading = true;

    this.db.FetchData({ 'session': this.session.abacusdesk_user }, 'master/getDepartment')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.departmentList = result['data']
          this.loading = false;
        }
        else {
          this.loading = false;
          this.dialog.error(result['statusMessage']);
        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  edit_project(myform: any) {
    this.loading = true;
    this.db.FetchData({ 'data': this.project_form, 'session': this.session.abacusdesk_user }, 'master/edit_project')
      .subscribe((result) => {
        if (result['statusCode'] == 200) {
          this.loading = false;
          this.dialog.success('Project', 'Edit Successfully !!!');
          this.router.navigate(['/project']);
        }
        else {
          this.loading = false;
          this.dialog.error(result['case']);

        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });

  }


  back(): void {
    this.location.back()
  }


}
