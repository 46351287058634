import { Component, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { MatDialog, MatTabGroup } from '@angular/material';
import { SessionService } from '../session.service';
import { Location } from '@angular/common';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html'
})
export class ProjectDashboardComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  projectCount: any = [];
  countLoader: boolean = false;
  prListLoader: boolean = false;
  teamLoader: boolean = false;
  departmentLoader: boolean = false;
  todayLoader: boolean = false;
  taskLoader: boolean = false;
  teamContribution: any = [];
  taskModification: any;
  taskPriority: any;
  teamHours: any;
  taskStatistics: any;
  teamTasks: any = [];
  search: any = {};
  userDataArray: any = [];
  loader: boolean = false;
  user_count: any = [];
  start: any = "0";
  pagelimit: any = "100";
  filter: any = {};
  encrpt_id: any = '';
  tabs = [];
  tabValue: any = 'DMS';
  lastPageData: any = {};
  taskSource: any;
  firstValue: boolean = true;
  stageData: any = {};


  constructor(public db: DatabaseService, public location: Location, public route: ActivatedRoute, public alrt: MatDialog, public session: SessionService, public dialog: DialogComponent) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lastPageData = this.route.queryParams['_value'];
      this.getDepartment();
    });
  }


  back(): void {
    this.location.back()
  }




  date_format(type): void {
    if (type == 'date_created') {
      this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
    }
    else if (type == 'assignedDate') {
      this.filter.assignedDate = moment(this.filter.assignedDate).format('YYYY-MM-DD');
    }
    else if (type == 'closed_date') {
      this.filter.closed_date = moment(this.filter.closed_date).format('YYYY-MM-DD');
    }
    else if (type == 'completed_date') {
      this.filter.completed_date = moment(this.filter.completed_date).format('YYYY-MM-DD');
    }
  }


  getTabValue(value) {
    this.tabValue = value.tab.textLabel;
    this.getStage();
    this.getData();
    this.getTaskDetail();
    this.getTaskPriority();
    this.getHours();
    this.firstValue = false;
  }

  getDepartment() {
    this.db.FetchData({ 'project_id': this.lastPageData.project_id }, 'Dashboard/departmentList')
      .subscribe(result => {
        this.tabs = result['data'];

        if (this.firstValue == true) {
          for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i]['department'] == 'SFA' && this.tabs[i]['check'] == '1') {
              this.tabValue = 'SFA';
              this.tabGroup.selectedIndex = 0;
              return

            }
            if (this.tabs[i]['department'] == 'DMS' && this.tabs[i]['check'] == '1') {
              this.tabValue = 'DMS';
              this.tabGroup.selectedIndex = 1;
              return
            }
            if (this.tabs[i]['department'] == 'LOYALTY' && this.tabs[i]['check'] == '1') {
              this.tabValue = 'LOYALTY';
              this.tabGroup.selectedIndex = 2;
              return

            }
            if (this.tabs[i]['department'] == 'SERVICE' && this.tabs[i]['check'] == '1') {
              this.tabValue = 'SERVICE';
              this.tabGroup.selectedIndex = 3;
              return

            }
            if (this.tabs[i]['department'] == 'DISPATCH' && this.tabs[i]['check'] == '1') {
              this.tabValue = 'DISPATCH';
              this.tabGroup.selectedIndex = 4;
              return

            }
          }
          this.getStage();
          this.getData();
          this.getTaskDetail();
          this.getTaskPriority();
        }
      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
      });

  }


  getData() {
    this.countLoader = true;
    this.db.FetchData({ 'project_id': this.lastPageData.project_id, 'department': this.tabValue }, 'Dashboard/totalTask')
      .subscribe(result => {
        this.projectCount = result['data'];
        this.countLoader = false;

      }, error => {
        this.countLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }
  getTaskDetail() {
    this.db.FetchData({ 'project_id': this.lastPageData.project_id, 'department': this.tabValue }, 'Dashboard/taskDetails')
      .subscribe(result => {
        this.taskPriority = {
          "chart": {
            "theme": "fusion",
          },
          "data": [{ 'label': 'High', 'Value': result['data']['All_high'] }, { 'label': 'Normal', 'Value': result['data']['All_normal'] }, { 'label': 'Critical', 'Value': result['data']['All_critical'] },]
        };

        this.taskModification = {
          "chart": {
            "theme": "fusion",
          },
          "data": [{ 'label': 'High', 'Value': result['data']['modification_high'] }, { 'label': 'Normal', 'Value': result['data']['modification_normal'] }, { 'label': 'Critical', 'Value': result['data']['modification_critical'] },]
        };


      }, error => {
        this.countLoader = false;
      });
  }


  hours: any = [];
  projectHours: any = {};
  projectMin: any = {}


  getHours() {
    this.hours = [];
    this.db.FetchData({ 'project_id': this.lastPageData.project_id, 'department': this.tabValue }, 'Dashboard/projectWorkingHours')
      .subscribe(result => {
        this.projectHours = result['total_working_hours'];
        this.projectMin = result['total_working_minutes'];
        for (let i = 0; i < result['data'].length; i++) {
          this.hours.push({ "label": result['data'][i].name, "value": result['data'][i].total_hours });
        }
        this.teamHours = {
          "chart": {
            "xAxisName": "Team Memeber",
            "yAxisName": "Working Hours",
            "theme": "fusion",
          },
          "data": this.hours
        };

      }, error => {
        this.countLoader = false;
      });
  }

  getStage() {
    this.countLoader = true;
    this.db.FetchData({ 'project_id': this.lastPageData.project_id, 'department': this.tabValue }, 'Dashboard/projectStage')
      .subscribe(result => {
        this.stageData = result['data'];
        this.countLoader = false;

      }, error => {
        this.countLoader = false;
        this.dialog.error('Something went wrong... Try Again !!!');
      });
  }



  getTaskPriority() {
    this.loader = true;
    this.db.FetchData({ 'project_id': this.lastPageData.project_id, 'department': this.tabValue }, 'Dashboard/teamContribution')
      .subscribe(result => {
        this.teamContribution = result['data'];
        this.loader = false;
      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
      });

  }



  taskOpen(id, project_id, type, task): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: type == 'userTask' ? '1024px' : '768px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'project_id': project_id,
        'id': id,
        'from': 'projectList',
        'type': type,
        'task': task,
      }

    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }



  openDialog(modaltype, id, department, type): void {
    const dialogRef = this.alrt.open(DashboardModalComponent, {
      width: '1024px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'model_type': modaltype,
        'type': type,
        'department': department,
        'id': id,
      }

    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }



  task_count: any = {};
  data: any = {}
  rqst_count: any = '';
  total_page: any = ''
  pagenumber: any = '';







}

