import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from './database.service';
import { DialogComponent } from './DialogComponent';

import { esLocale } from 'ngx-bootstrap';

@Injectable({
  providedIn: 'root'
})

export class SessionService implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, public db: DatabaseService, public dialog: DialogComponent) { }

  abacusdesk_user: any = {};

  ngOnInit() {
    this.abacusdesk_user.ari_login = false;
  }

  GetSession(): Observable<any> {
    this.abacusdesk_user = JSON.parse(localStorage.getItem('abacusdesk_user')) || [];
    return of(this.abacusdesk_user);
  }

  LogOutSession() {
    this.abacusdesk_user = {};
    this.abacusdesk_user.ari_login = false;
    this.db.can_active = '';
    localStorage.removeItem('abacusdesk_user');
  }

  NextUrl: any = '';
  NextUrl_temp: any = '';
  SetSession(data: any) {


    this.db.FetchData(data, 'login/submit')
      .subscribe((data: any) => {
        if (data) {
          this.abacusdesk_user = data;
          this.abacusdesk_user.ari_login = true;
          this.db.can_active = '1';
          localStorage.setItem('abacusdesk_user', JSON.stringify(this.abacusdesk_user));
          if (this.abacusdesk_user.access_level == 1 || this.abacusdesk_user.access_level == 3 || this.abacusdesk_user.access_level == 4 || (this.abacusdesk_user.access_level == '2' && this.abacusdesk_user.role == 'Technical Head')) {
            this.NextUrl = this.route.snapshot.queryParams['returnUrl'] || '/project';
            this.router.navigate([this.NextUrl]);
          }
          else if (this.abacusdesk_user.access_level == 'client') {
            this.NextUrl_temp = this.route.snapshot.queryParams['returnUrl'] || '/task-support';
            this.router.navigate([this.NextUrl_temp]);
          }
          else if (this.abacusdesk_user.access_level == 2 || this.abacusdesk_user.access_level == 7) {
            this.NextUrl_temp = this.route.snapshot.queryParams['returnUrl'] || '/task';
            this.router.navigate([this.NextUrl_temp]);
          }
          else {
            this.dialog.error('Not Access for login');
          }

        }
        else {
          this.dialog.error('Please check your Username and Password');
        }
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });

  }


}