import { Component, Input, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { SessionService } from '../session.service';
import { DialogComponent } from '../DialogComponent';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-code-live',
  templateUrl: './code-live.component.html'
})
export class CodeLiveComponent implements OnInit {

  @Input() dataToReceive: any;
  pt0: any;
  routes: any;
  status: any;
  hide: boolean = true;
  active_tab: any;
  url: any;
  loader: boolean = false;
  sessiondata: any = {};
  filter: any = {};
  start: any = 0;
  pagelimit: any = 100;
  pagenumber: any = 0;
  total_page: any = 0;

  constructor(public db: DatabaseService, public dialog: DialogComponent, public alrt: MatDialog, public session: SessionService) {

    this.url = this.db.upload + 'build/';
    this.sessiondata = this.session.GetSession();

  }

  ngOnInit() {
    if (this.dataToReceive != undefined) {
      this.pt0 = this.dataToReceive.padding0;
      this.hide = this.dataToReceive.hide;
      if (typeof this.dataToReceive.status === 'object' && Object.keys(this.dataToReceive.status).length === 0 || typeof this.dataToReceive.routes === 'object' && Object.keys(this.dataToReceive.routes).length === 0) {
        this.active_tab = this.dataToReceive.status;
        this.getData();
      }
      else {
        this.active_tab = "Pending";
        this.getData();
      }
    }


  }

  data: any = [];
  tabcount: any = {};
  page_count: any = {};

  getData() {
    this.loader = true;
    this.db.FetchData({ 'session': this.sessiondata.value, 'active_tab': this.active_tab, 'filter': this.filter, 'start': this.start, 'pagelimit': this.pagelimit }, 'Master/taskLiveApiList')
      .subscribe((result: any) => {
        if (result['statusCode'] == 200) {
          this.loader = false;
          this.data = result['data'];
          this.tabcount = result['count'];
          this.page_count = result['count'];
          const statusMapping = {
            'Pending': 'pending',
            'Lived': 'Lived',
            'Verified': 'Verified',
            'Reject': 'Reject'
          };

          if (statusMapping.hasOwnProperty(this.active_tab)) {
            this.page_count = result['count'][statusMapping[this.active_tab]];
          }
          this.total_page = Math.ceil(this.page_count / this.pagelimit);
          this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
        }
        else {
          this.loader = false;
          this.dialog.error(result['statusMsg']);
        }
      }, error => {
        this.loader = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }

  closeTaskId: any = [];

  GetId(event, index) {
    const isChecked = event.checked;
    const itemId = this.data[index].id;
    if (isChecked) {
      this.closeTaskId.push(this.data[index]);
    } else {
      const i = this.closeTaskId.findIndex(row => row.id === itemId);
      if (i !== -1) {
        this.closeTaskId.splice(i, 1);
      }
    }


  }


  openDialog(type, data): void {
    const dialogRef = this.alrt.open(TaskModalComponent, {
      width: type == 'codeHistory' ? '1024px' : '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'model_type': type,
        'data': this.closeTaskId,
        'main_table_id': data
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.closeTaskId = [];
      }
      this.getData();
    });
  }


  changeStatus() {
    let msg = 'Are you sure ?'
    this.dialog.commanAlert(msg).then((result) => {
      if (result) {
        this.loader = true;
        this.db.FetchData({ 'data': { 'live_status': 'Verified', 'live_list': this.closeTaskId }, 'session': this.sessiondata.value }, 'master/taskApiLiveStatusChange')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              this.getData()
              this.dialog.success('Status Changed', result['statusMsg']);
            }
            else {
              this.dialog.error(result['statusMsg']);
            }

          }, error => {
            this.dialog.error('Something went wrong... Try Again !!!');
            this.loader = false;
          });
      }
    });
  }


  errorMsg(data) {
    this.dialog.error(data.readyToLiveMessage);
  }


  date_format(type): void {
    if (type == 'date_created') {
      this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
      this.getData();
    }
    if (type == 'assignedDate') {
      this.filter.assignedDate = moment(this.filter.assignedDate).format('YYYY-MM-DD');
      this.getData();
    }
    if (type == 'completed_date') {
      this.filter.completed_date = moment(this.filter.completed_date).format('YYYY-MM-DD');
      this.getData();
    }
  }


  pervious() {
    this.start = this.start - this.pagelimit;
    this.getData();
  }

  nextPage() {
    this.start = this.start + this.pagelimit;
    this.getData();
  }

  refresh() {
    this.filter = {};
    this.getData();
  }

}
