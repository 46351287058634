import { Component, OnInit } from '@angular/core';
// 
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-working-report',
  templateUrl: './working-report.component.html',
  styleUrls: ['./working-report.component.scss']
})
export class WorkingReportComponent implements OnInit {

  data: any = {};
  date: any
  current_month: any
  current_month_name: any
  current_year: any
  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  working_list: any = []
  encrpt_id: any;
  user_name: any;
  options = {};

  constructor(public db: DatabaseService, public session: SessionService, public router: Router, public dialog: DialogComponent, public navCtrl: NgxNavigationWithDataComponent, public route: ActivatedRoute,) {
    this.route.params.subscribe(params => {
      this.encrpt_id = params.id;
    });
  }

  ngOnInit() {
    // const elemss = document.querySelectorAll('.dropdown-trigger');
    //     const instancess = M.Dropdown.init(elemss, this.options);

    this.date = new Date();
    this.current_month = this.date.getMonth();
    this.current_year = this.date.getFullYear();
    this.current_month_name = this.monthNames[this.current_month]
    this.get_working_list();

  }
  //   reinit_dropdown() {

  //     setTimeout(() => {
  //         const elemss = document.querySelectorAll('.dropdown-trigger');
  //         const instancess = M.Dropdown.init(elemss, this.options);
  //     }, 500);
  // }

  get_working_list() {
    let data = { 'month': this.current_month + 1, 'year': this.current_year, 'id': this.encrpt_id }

    this.db.FetchData({ data, 'session': this.session.abacusdesk_user }, 'master/getWorkingReportData').subscribe((result: any) => {
      this.working_list = result.dateArray
      this.user_name = result.name
      // this.reinit_dropdown();

    })
    // this.reinit_dropdown();

  }

}
