import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  form: any = {};
  input_type: any = '';
  hide = true;


  constructor(public session: SessionService, public db: DatabaseService) {
  }

  ngOnInit() {
    this.input_type = 'password';
  }


  show_password() {
    this.input_type = 'password';
  }


  hide_password() {
    this.input_type = 'text';
  }

  login() {
    this.session.SetSession(this.form);
  }

}
