import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogComponent } from '../DialogComponent';
import { DatabaseService } from '../database.service';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html'
})
export class TaskModalComponent implements OnInit {
  loader: boolean = false
  data: any = {};
  savingFlag: boolean = false;
  btnFlag: boolean = false;
  listData: any = {};
  item: any = [];
  projectItems: any = [];
  formData: any = {};
  constructor(@Inject(MAT_DIALOG_DATA) public modalData, public dialogRef: MatDialogRef<TaskModalComponent>, public session: SessionService, public dialog: DialogComponent, public db: DatabaseService) {
    this.data.code_live = 'No';
    if (this.modalData.model_type == 'codeHistory') {
      console.log(modalData);

      this.getLiveList();
    }

    if (this.modalData.model_type == 'Draft Code Request') {
      this.data.code_live = 'Yes';
      this.GetTaskList();
    }
    if (this.modalData.model_type == 'Verified TasK By Tester') {
      modalData.data.live_flag = 0;
    }
    if (this.modalData.model_type == 'Project Details') {
      console.log(this.modalData);

      this.getProjectDetails();
    }

    this.data.hours='0';
    this.data.minute='0';
  }

  ngOnInit() {
  }





  addToList() {
    console.log(this.session.abacusdesk_user);

    if (this.session.abacusdesk_user.developer_type == "Backend" && !this.listData.url) {
      this.dialog.error('Api Url required');
      return
    }
    if (this.session.abacusdesk_user.developer_type == "Backend" && !this.listData.api_type) {
      this.dialog.error('Api Type required');
      return
    }

    if (this.session.abacusdesk_user.role_id == '4' && !this.listData.function_name) {
      this.dialog.error('Function name required');
      return
    }
    if (this.session.abacusdesk_user.role_id == '4' && !this.listData.file_name) {
      this.dialog.error('File name required');
      return
    }

    if (this.session.abacusdesk_user.role_id == '4' && this.listData.function_name.trim() !== this.listData.function_name) {
      this.dialog.error('Funcation name input remove blank space');
      return
    }
    if (this.session.abacusdesk_user.role_id == '4' && this.listData.file_name.trim() !== this.listData.file_name) {
      this.dialog.error('File name input remove blank space');
      return
    }

    if (this.session.abacusdesk_user.role_id == '3' && this.listData.url.trim() !== this.listData.url) {
      this.dialog.error('Url input remove blank space');
      return
    }

    this.btnFlag = true;
    this.item.push({ 'url': this.listData.url, 'api_type': this.listData.api_type, 'function_name': this.listData.function_name, 'file_name': this.listData.file_name, 'table_name': this.listData.table_name, 'column_name': this.listData.column_name, 'table_action': this.listData.table_action })
    this.blankValue();
  }

  blankValue() {
    this.listData.url = '';
    this.listData.table_name = '';
    this.listData.column_name = '';
    this.listData.table_action = '';
    this.listData.function_name = '';
    this.listData.api_type = '';
    this.listData.file_name = '';
    this.btnFlag = false;
  }

  delete(i) {
    this.item.splice(i, 1);
  }




  submit() {
    let msg
    if (this.modalData.model_type == 'Close Task') {
      msg = 'Task Completed ?';
      this.data.live_code_data = this.item;
      this.data.parent_task_id = this.modalData.taskData.parent_task_id;
      this.data.project_name = this.modalData.taskData.project_name;
      this.data.project_id = this.modalData.taskData.project_id;
    }
    if (this.modalData.model_type == 'Estimate Time' || this.modalData.model_type == 'Draft Code Request' || this.modalData.model_type == 'Verified TasK By Tester') {
      msg = 'Are you sure ?'
    }
    if (this.modalData.model_type == 'Draft Code Request') {
      this.data.live_code_data = this.item;
    }


    if (this.data.code_live == 'Yes' && this.item.length === 0 && this.session.abacusdesk_user.developer_type == 'Backend') {
      this.dialog.error('Add to list data');
      return
    }


    this.dialog.commanAlert(msg).then((result) => {

      if (result) {
        this.loader = true;
        if (this.modalData.model_type != 'Draft Code Request') {
          this.data.task_id = this.modalData.id;
        }
        this.data.user_id = this.session.abacusdesk_user.id;
        this.data.type = this.modalData.model_type;


        if (this.data.code_live == 'No') {
          this.item = [];
        }

        this.db.FetchData({ 'data': this.data, 'session': this.session.abacusdesk_user }, this.modalData.model_type == 'Draft Code Request' ? 'master/addDraftAPi' : this.modalData.model_type == 'Verified TasK By Tester' ? 'master/verify_multiple_task' : 'master/close_task')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              this.dialogRef.close(true);
              this.loader = false;
              this.dialog.success('Success', result['statusMessage']);
            }
            else {
              this.dialog.error(result['statusMessage']);
            }

          }, error => {
            this.dialog.error('Something went wrong... Try Again !!!');
            this.loader = false;
          });
      }
    });
  }

  selectedFile: any = [];
  fileFormat: boolean = false;


  onFileSelected(event: any) {
    const files = event.target.files;
    if (files.length > 5 || this.selectedFile.length > 5) {
      this.dialog.error('Maximum five attachment allow same time');
      event.target.value = null;
      return;
    }

    let byte = 1000000
    if (event.target.files[0]['size'] > (byte * 50)) {
      this.dialog.error('size is too be large, maximum file size is 50 MB.');
      return;
    }

    for (var i = 0; i < event.target.files.length; i++) {
      const allowedFormats = ['.zip', '.jpg', '.jpeg', '.png', '.csv', '.docx', '.pdf', '.xlsx', '.txt'];
      const fileFormat = event.target.files[i].name.substring(event.target.files[i].name.lastIndexOf('.'));
      if (allowedFormats.includes(fileFormat.toLowerCase())) {
        this.selectedFile.push(event.target.files[i]);
        this.fileFormat = false;
        // this.upload_user_data_excel();
      } else {
        this.fileFormat = true;
        this.dialog.error('File format not correct');
      }
    }

  }





  binaryData = new FormData();


  submitReq() {
    // if (this.selectedFile.length == 0 && this.session.abacusdesk_user.developer_type == 'Frontend') {
    //   this.dialog.error('Attachment required');
    //   return;
    // }
    this.data.project_array = this.modalData.data;
    this.data.project_name = this.modalData.data[0]['project_name'];
    this.data.project_id = this.modalData.data[0]['project_id'];


    this.savingFlag = true;
    this.db.FetchData({ 'data': this.data, 'session': this.session.abacusdesk_user }, "Master/taskApiStatusChange").subscribe(result => {
      if (result['statusCode'] == 200) {

        if (this.selectedFile.length > 0) {
          this.uploadDocument(result['id']);
        }
        else {
          this.savingFlag = false
          this.dialogRef.close(true);
          this.dialog.success('Request Send', result['statusMsg']);
        }


      }
      else {
        this.loader = false
        this.dialog.error(result['statusMsg']);
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }



  uploadDocument(id) {
    for (var i = 0; i < this.selectedFile.length; i++) {
      this.binaryData.append("image" + i, this.selectedFile[i], this.selectedFile[i].name);
    }

    this.db.FileData(this.binaryData, "Master/insertDocForTaskApi/" + id + '/' + this.session.abacusdesk_user.id).subscribe((resp) => {
      if (resp['statusCode'] == 200) {
        this.savingFlag = false;
        this.dialogRef.close(true);
        this.dialog.success('Request Send', ' Successfully!');
        this.loader = false
        this.savingFlag = false;
      } else {
        this.dialog.error(resp['statusMsg']);
        this.savingFlag = false;
      }
    });
  }



  updateStatus() {
    let msg = 'Are you sure ?'
    this.dialog.commanAlert(msg).then((result) => {
      if (result) {
        this.loader = true;
        this.formData.live_list = this.modalData.data;
        this.formData.user_id = this.session.abacusdesk_user.id;
        this.db.FetchData({ 'data': this.formData, 'session': this.session.abacusdesk_user }, 'master/taskApiLiveStatusChange')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              this.dialogRef.close(true);
              this.loader = false;
              this.dialog.success('Status Changed', result['statusMsg']);
            }
            else {
              this.dialog.error(result['statusMsg']);
            }

          }, error => {
            this.dialog.error('Something went wrong... Try Again !!!');
            this.loader = false;
          });
      }
    });
  }



  getLiveList() {
    this.loader = true;
    this.db.FetchData({ 'data': this.modalData.main_table_id.id, }, 'master/subTaskForApiLive')
      .subscribe(result => {
        if (result['statusCode'] == 200) {
          this.loader = true;
          this.item = result['data']
          this.loader = false;
        }
        else {
          this.loader = false;
          this.dialog.error(result['statusMsg']);
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
        this.loader = false;
      });
  }

  search: any = {};
  task_list: any = [];
  GetTaskList() {
    this.loader = true;
    this.search.status = 'Closed';
    this.db.FetchData({ 'search': this.search, 'login_data': this.session.abacusdesk_user }, 'master/GetTaskListNew')
      .subscribe((result: any) => {
        let uniqueArray = [];
        this.task_list = result['task_list'];
        //   this.task_list = uniqueArray.reduce((accumulator, current) => {
        //     if (!accumulator.some(item => item.project_id === current.project_id)) {
        //         accumulator.push(current);
        //     }
        //     return accumulator;
        // }, []);
      }, error => {
        this.dialog.error('Something went wrong !!! Try Again...');
      });


  }

  getProjectDetails() {
    this.loader = true;
    this.db.FetchData({ 'data': this.modalData.data, 'login_data': this.session.abacusdesk_user }, 'master/GetProjectDetails')
      .subscribe((result: any) => {
        this.projectItems = result['task_list'];
        this.loader = false;

      }, error => {
        this.loader = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });


  }


  submitLiveRequestStatus(data, status) {
    let msg = 'Are you sure ?'
    console.log(data);
    this.dialog.commanAlert(msg).then((result) => {
      if (result) {
        this.loader = true;
        this.formData.main_table_data = this.modalData.main_table_id;
        this.formData.live_status = status;
        if (data != undefined && data != '') this.formData.subTableData = data;


        this.db.FetchData({ 'data': this.formData, 'session': this.session.abacusdesk_user }, 'master/taskApiLiveStatusChangeForAll')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              // this.dialogRef.close(true);
              this.getData('Pending');
              this.dialog.success('Status Changed', result['statusMsg']);
            }
            else {
              this.dialog.error(result['statusMsg']);
            }

          }, error => {
            this.dialog.error('Something went wrong... Try Again !!!');
            this.loader = false;
          });
      }
    });
  }

  taskApiLiveStatusToVerified(data, status) {
    let msg = 'Are you sure ?'
    console.log(data);
    this.dialog.commanAlert(msg).then((result) => {
      if (result) {
        this.loader = true;
        this.formData.main_table_data = this.modalData.main_table_id;
        this.formData.live_status = status;
        if (data != undefined && data != '') this.formData.subTableData = data;


        this.db.FetchData({ 'data': this.formData, 'session': this.session.abacusdesk_user }, 'master/taskApiLiveStatusToVerified')
          .subscribe(result => {
            if (result['statusCode'] == 200) {
              // this.dialogRef.close(true);
              this.getData('Lived');
              this.dialog.success('Status Changed', result['statusMsg']);
            }
            else {
              this.dialog.error(result['statusMsg']);
            }

          }, error => {
            this.dialog.error('Something went wrong... Try Again !!!');
            this.loader = false;
          });
      }
    });
  }


  getData(activetab) {
    this.loader = true;
    this.db.FetchData({ 'session': this.session.abacusdesk_user, 'active_tab': activetab, 'filter': {}, 'start': 0, 'pagelimit': 100 }, 'Master/taskLiveApiList')
      .subscribe((result: any) => {
        if (result['statusCode'] == 200) {
          this.loader = false;
          let data = result['data'];
          this.modalData.main_table_id = data.find(r => r.id == this.formData.main_table_data.id);
          if (this.modalData.main_table_id == undefined) {
            this.dialogRef.close(true);
          } else {
            this.getLiveList();
          }
        }
        else {
          this.loader = false;
          this.dialog.error(result['statusMsg']);
        }
      }, error => {
        this.loader = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });
  }


}
