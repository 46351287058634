import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { DialogComponent } from '../DialogComponent';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { SessionService } from '../session.service';
import * as moment from 'moment';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  currentMonth: any;
  currentYear: any;
  TodayDate = new Date().toISOString().slice(0, 10);
  monthNames: string[];
  date: any;
  currentMonth_no: any;
  daysInThisMonth: any = [];
  daysInLastMonth: any = [];
  daysInNextMonth: any = [];
  dateArray: any = [];
  data: any = {};
  skLoading: boolean = false;
  filter: any = {}
  search: any = {}
  today: any;
  tommorrow: any;
  counters: any;
  reportType: any = 'Team Estimation V/S Delivered Task';
  projectItems: any = [];
  reportsData: any = [];
  overAllTotals: any = {};

  constructor(public db: DatabaseService, public session: SessionService, public alrt: MatDialog, public location: Location, public dialog: DialogComponent,) {
    this.date = new Date();
    this.today = new Date();
    this.tommorrow = new Date().getDate();
    console.log(this.tommorrow);

    this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentYear = this.date.getFullYear();
    this.currentMonth_no = this.monthNames.findIndex(r => r == this.currentMonth);
    if (this.session.abacusdesk_user.access_level == 1) {
      this.filter.department_name = 'SFA';
    }
    else {
      this.filter.department_name = this.session.abacusdesk_user.department
    }
    this.getData();



  }

  ngOnInit() {
  }




  getData() {
    this.skLoading = true;
    this.db.FetchData({ 'Month': this.currentMonth_no + 1, 'Year': this.currentYear, 'department': this.filter.department_name }, 'master/taskCalendor').subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.dateArray = result['dates'];
        this.counters = result['outer_array'];

        this.skLoading = false;
        this.getDaysOfMonth();
      }
      else {
        this.skLoading = false;
        this.dialog.error(result['statusMsg']);
      }
    }, error => {
      this.skLoading = false;
      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }


  goToLastMonth() {
    this.date = new Date(this.currentYear, this.currentMonth_no, 0);
    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentYear = this.date.getFullYear();
    this.currentMonth_no = this.date.getMonth();
    this.getData();
  }

  goToNextMonth() {
    this.date = new Date(this.currentYear, this.currentMonth_no + 2, 0);
    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentYear = this.date.getFullYear();
    this.currentMonth_no = this.date.getMonth();
    this.getData();
  }

  getDaysOfMonth() {
    let i
    this.daysInThisMonth = [];
    this.daysInLastMonth = [];
    this.daysInNextMonth = [];
    // var firstDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
    var firstDayThisMonth = new Date(this.date.getFullYear(), this.currentMonth_no, 1).getDay();
    firstDayThisMonth == 0 ? firstDayThisMonth = 6 : firstDayThisMonth = (firstDayThisMonth - 1);
    var prevNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for (i = prevNumOfDays - (firstDayThisMonth - 1); i <= prevNumOfDays; i++) {
      this.daysInLastMonth.push(i);
    }
    // var thisNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    var thisNumOfDays = new Date(this.date.getFullYear(), this.currentMonth_no + 1, 0).getDate();

    for (i = 0; i < thisNumOfDays; i++) {
      let month = ((this.currentMonth_no + 1) < 10) ? '0' + (this.currentMonth_no + 1) : (this.currentMonth_no + 1)
      let date = ((i + 1) < 10) ? '0' + (i + 1) : (i + 1)
      let fulldate = this.date.getFullYear() + '-' + month + '-' + date
      let date_data = this.dateArray.filter(x => x.date === fulldate)[0];
      this.daysInThisMonth[i] = { 'day': i + 1, 'date': fulldate, 'day_name': date_data.day, 'cal_date': date_data.date, 'estimation_task': date_data.estimation_task, 'delivered_task': date_data.delivered_task, 'estimation_task_data': date_data.estimation_task_data, 'delivered_task_data': date_data.delivered_task_data, 'visible_flag': date_data.visible_flag, 'not_delivered_task': date_data.not_delivered_task };
    }
    console.log(this.daysInThisMonth);
    var lastDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDay();

    if (lastDayThisMonth > 0) {
      for (i = 0; i < (7 - lastDayThisMonth); i++) {
        this.daysInNextMonth.push(i + 1);
      }
    }

  }




  back(): void {
    this.location.back()
  }



  openDialog(data, type): void {
    console.log(data);
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: type == 'date_range' ? '500px' : '1024px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'from': 'projectList',
        'data': data,
        'type': type,
        'department': this.filter.department_name,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.success == true) {
        this.search.date_from = result.search.date_from;
        this.search.date_to = result.search.date_to;
        if (this.reportType == 'Project Monthly Reports') this.getReport();
      }
    });



  }

  noexits() {
    this.dialog.error('Task not exists');
  }




  toggle: boolean = false;
  toggleNav() {
    this.toggle = !this.toggle;
  }


  getReport() {
    this.skLoading = true;
    this.db.FetchData({ 'login_data': this.session.abacusdesk_user, 'filter': this.search }, "master/getAllProjectReports").subscribe((result => {
      if (result['statusCode'] == 200) {
        this.skLoading = false;
        this.reportsData = result['data'];
        this.overAllTotals = result['overall_totals'];
      } else {
        this.skLoading = false;
      }
    }));

  }

  refresh() {
    this.search = {};
    this.getReport();
  }

  getDateRange() {
    this.search.date_from = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.search.date_from = moment(this.search.date_from).format("YYYY-MM-DD")
    this.search.date_to = moment(this.today).format("YYYY-MM-DD")

    console.log(this.search);
  }

}
