import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/database.service';
import { DialogComponent } from 'src/app/DialogComponent';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  constructor(public db: DatabaseService, public dialog: DialogComponent, public session: SessionService) { }

  ngOnInit() {
    this.GetUserList();
  }

  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }



  loader: any = '';
  progress: any = '';

  start: any = "0";
  pagelimit: any = "20";
  search: any = {};
  rqst_count: any = '';
  total_page: any = ''
  pagenumber: any = '';

  user: any = [];
  user_count: any = [];

  GetUserList(pagelimit: any = 20, start: any = 0, action: any = '') {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    if (action == "refresh") {
      this.search = {};
    }



    this.db.FetchData({ 'search': this.search, 'start': this.start, 'pagelimit': this.pagelimit }, 'master/user_list')
      .subscribe((result: any) => {
        this.user = result['user_list'];
        this.user_count = result['user_count']
        this.rqst_count = result['count'];
        this.total_page = Math.ceil(this.rqst_count / this.pagelimit);
        this.pagenumber = (this.start / this.pagelimit) + 1;
        this.loader = '';
        this.progress = '';

      }, error => {
        this.dialog.error('Something went wrong !!! Try again... ');
      });
  }


  delete_user(index: any, user_id: any) {
    this.dialog.delete('User Data').then((result) => {
      if (result) {
        this.db.FetchData({ 'id': user_id, 'session': this.session.abacusdesk_user }, 'master/delete_user')
          .subscribe(res => {
            this.user.splice(index, 1);
            this.dialog.success('Deleted', 'User has been deleted.');
          }, err => {
            this.dialog.error('Something went wrong! Try Again ...');
          });
      }
    });
  }




}
