import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from '../database.service';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  data: any = {};
  states: any = [];
  reportingOne = [];
  user = [];
  list: any = [];
  headersCus: any = [];
  headersEmp: any = [];
  today: any
  reportType: any = {}
  noResult: boolean = false;
  downurl: any = ''
  savingFlag: boolean = false;
  excelFlag: boolean = false;
  map: any
  skLoading: boolean = false;
  filter: any = {};
  reportsData: any = []
  pagelimit: any = 50;
  pageCount: any;
  total_page: any;
  pagenumber: any = 0;
  start: any = 0;
  overAllTotals: any = {};

  constructor(public route: ActivatedRoute, public service: DatabaseService, public session: SessionService) {

    this.today = new Date();
    this.route.params.subscribe(params => {
      console.log(params.type);

      this.reportType = params['type'];
      this.getReport();
      this.excelFlag = false;
      this.savingFlag = false
    });

  }


  ngAfterViewInit() {


  }




  ngOnInit() {

  }



  pervious() {
    this.start = this.start - this.pagelimit;
    this.getReport();
  }

  nextPage() {
    this.start = this.start + this.pagelimit;
    this.getReport();
  }


  getReport() {

    if (this.reportType == 'Project_Monthly_Reports') {
      this.skLoading = true;
      this.service.FetchData({ 'start': this.start, 'pagelimit': this.pagelimit, 'login_data': this.session.abacusdesk_user,'filter':this.filter }, "master/getAllProjectReports").subscribe((result => {
        if (result['statusCode'] == 200) {
          this.skLoading = false;
          this.reportsData = result['data'];
          this.pageCount = result['count'];
          this.overAllTotals = result['overall_totals'];
          this.pagenumber = Math.ceil(this.start / this.pagelimit) + 1;
          this.total_page = Math.ceil(this.pageCount / this.pagelimit);
        } else {
          this.skLoading = false;
        }
      }));
    }

  }

  refresh() {
    this.filter = {};
    this.getReport();
  }

}
