import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModalComponent } from 'src/app/common-modal/common-modal.component';
import { DatabaseService } from 'src/app/database.service';
import { SessionService } from 'src/app/session.service';


@Component({
  selector: 'app-quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.scss']
})
export class QuotationDetailComponent implements OnInit {
  quotation_detail: any = {};
  skLoading: boolean = false;
  quotation_summary: any = [];
  quotation_id: any = '';
  constructor(public route: ActivatedRoute, public service: DatabaseService, public router: Router, public session: SessionService, public alrt: MatDialog) { }

  ngOnInit() {
    this.quotation_id = this.route.params['_value'].id;
    this.getQuotationDetail();
  }


  getQuotationDetail() {
    this.skLoading = true;
    this.service.FetchData({ 'quotation_id': this.quotation_id }, 'master/getQuotationDetail').subscribe((resp) => {
      console.log(resp);
      this.quotation_detail = resp['result']
      this.quotation_summary = resp['result']['item'];
      setTimeout(() => {
        this.skLoading = false;
      }, 1000);
    })
  }

  back() {
    window.history.back();
  }

  goToQuotationEdit() {
    this.router.navigate(['/add-quotation/'], { queryParams: { "quotation_id": this.quotation_id } })
  }

  openDialog(id, type): void {
    const dialogRef = this.alrt.open(CommonModalComponent, {
      width: '500px',
      panelClass: 'cs-modal',
      disableClose: true,
      data: {
        'project_id': '',
        'id': id,
        'encrpt_id': '',
        'session': this.session,
        'from': 'projectList',
        'from_type': 'edit',
        'type': type,
        'task': '',
        'required_developer': ''
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {

        this.getQuotationDetail();
      }
    });
  }

  imageOpen(path) {
    window.open(path, '_system');
  }

}
