import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DialogComponent } from '../DialogComponent';
import { DatabaseService } from '../database.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../session.service';
import * as _ from 'lodash';
import { get } from 'jquery';


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  loader: boolean = false
  user_list: any = [];
  project_list: any = [];
  formData: any = {};
  filter: any = {};
  task_id: any = "";
  project_id: any = "";
  encrpt_id: any = "";
  session: any = "";
  from: any = "";
  type: any = "";
  task: any = "";
  user_form: any = {};
  currentDate: any;
  start_rating: any = {};
  star: any = 0;
  ratingCheck: boolean = false;
  task_type: any;
  myDate: Date;
  teamData: any = [];
  developer: any = [];
  savingFlag: boolean = false;
  logs: any = [];
  maxDate: any;
  image = new FormData();
  taskImage: any = [];
  errorMsg: boolean = false;
  userdata: any
  currentStatus: any = {};
  fileFormat: boolean = false;
  sugDeveloper: any = [];
  projectDetails: any = [];
  selected_image: any = [];
  excel_loader: any = false;
  excel_name: any = '';

  info: any = {};
  overAll: any = {};
  logsInfo: any = {};
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Type something. Test the Editor... ヽ(^。^)丿',
    translate: 'no',
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
      // ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      // ["link", "unlink", "image", "video"]
    ]
  };

  search: any = {};
  today_date: any = new Date();

  private formatDate(date: Date): string {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;

  }

  constructor(@Inject(MAT_DIALOG_DATA) public data, public userSession: SessionService, public dialog: DialogComponent, public db: DatabaseService, public router: Router, public dialogRef: MatDialogRef<CommonModalComponent>) {
    this.fileFormat = true;
    this.userdata = userSession;
    console.log(this.userdata.abacusdesk_user);

    this.currentDate = new Date();
    this.formData.estimated_working_hours = '0';
    const today = new Date();
    today.setDate(today.getDate() + 3);
    if (this.data.type == "clientDeliveryDate" && (this.data.data.task_type == "Issue" || this.data.data.task_type == "Modification")) {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      if (this.userSession.abacusdesk_user.access_level != '1') {
        this.maxDate = moment(this.data.data.expected_estimation_date).format('YYYY-MM-DD');
      }
      else {
        this.maxDate = '';
      }
      this.formData.estimated_working_minute = '0'
    }

    if (this.data.type != 'leave') {
      this.formData.assign_date = this.currentDate
    }


    this.formData.task_creation_type = 'Self'
    this.from = data.from;
    if (data.from == 'projectList' && data.type == 'logs') {
      this.logs = data.data;

    }
    if (this.from == 'projectList') {
      this.type = data.type
      this.task_id = data.id;
      this.project_id = data.project_id;
      this.encrpt_id = data.encrpt_id;
      this.session = data.session;
      this.task = data.task;
      if (this.task) {
        this.formData.task_description = this.task
      }


    }

    if (data.from == 'projectList' && data.type == 'projectDetail') {
      this.info = data;
      console.log(this.info);
      this.getProjectDetails();
    }

    if (data.from == 'projectList' && data.type == 'logsNew') {
      this.info = data.data;
      console.log(this.info);
      this.getTaskListLogs();
    }

    if (data.from == 'projectList' && data.type == 'asignTask') {
      let storeId = []
      for (let i = 0; i < data.task.length; i++) {
        storeId.push(data.task[i]['assign_id']);
      }
      this.formData.assign_to = storeId;
      this.user_list = data.task
    }

    if (this.from == 'projectList' && data.type == 'editUser') {
      this.user_form = data.task
    }

    if (this.from == 'projectList' && data.type == 'userTask') {
      this.getTask();
    }

    if (this.from == 'projectList' && data.type == 'addPoint') {
      this.myDate = new Date();
      this.getTeam()
    }
    if (this.from == 'projectList' && data.type == 'uploadPoints') {
      this.myDate = new Date();
      this.getTeam()
    }

    if (this.from == 'projectList' && data.type == 'task_and_hours_of_teams') {
      console.log(data);

      this.projectDetails = data.data.data;
      this.overAll = data.data.overall_totals;
    }



    if (this.from == 'projectList' && data.type == 'clientTaskEdit') {
      this.formData = data.data;
      if (data.data.repeated_task == '1') {
        this.formData.repeated_task = true
      }
      else {
        this.formData.repeated_task = false
      }
      this.getDepartment();
    }

    if (data.type == 'contactPersonAdd' || data.type == 'clientTaskAdd' || data.type == 'clientTaskEdit' || data.type == 'colorCodesShow') {
      this.getList();
    }

    if (this.from == 'projectList' && data.type == 'clientTaskEdit' || data.type == 'clientTaskAdd') {
      if (userSession.abacusdesk_user.access_level == '4' || userSession.abacusdesk_user.access_level == '3' || userSession.abacusdesk_user.access_level == '1') {
        console.log(data.project_routes)
        if (data.project_routes == 'project-detail') {
          this.formData.project_id = this.data.project_id;
        }
        else {
          this.formData.project_id = this.data.data.project_id;
        }
        this.GetProjectList('');

      }
      // if (userSession.abacusdesk_user.access_level == '4') {
      //   
      // }
      this.myDate = new Date();
      this.getDepartment();

    }

    if (this.from == 'projectList' && data.type == 'clientDeliveryDate') {
      this.formData = data.data;
      this.formData.task_type == 'Issue' && (this.formData.task_payment_type = 'Non Paid');
      this.formData.estimated_working_minute = 0;
    }
    if (this.from == 'projectList' && data.type == 'updateProjectType') {
      this.project_id = data.data.project_id;
      this.formData = data.data;
      console.log(this.formData.task_type);

      this.currentStatus = data.data.status;

      this.getDepartment();
    }
    if (this.from == 'projectList' && data.type == 'verifyQuotation') {
      this.currentStatus = data.task.status;
      this.formData = data.task;
    }
    if (this.from == 'projectList' && data.type == 'sendMail') {
      this.formData.id = data.id;
      if (userSession.abacusdesk_user.department.toLowerCase() == 'sfa') {
        this.formData.email_cc = 'ankita.bhatt@basiq360.in,vikas@abacusdesk.co.in,sailesh@abacusdesk.co.in,asaph@basiq360.com';
      } else if (userSession.abacusdesk_user.department.toLowerCase() == 'dms') {
        this.formData.email_cc = 'sunil@basiq360.in,vikas@abacusdesk.co.in,sailesh@abacusdesk.co.in,asaph@basiq360.com';
      }
      else if (userSession.abacusdesk_user.department.toLowerCase() == 'loyalty') {
        this.formData.email_cc = 'navneet.kumar@basiq360.in,vikas@abacusdesk.co.in,sailesh@abacusdesk.co.in,asaph@basiq360.com';
      }
      else if (userSession.abacusdesk_user.department.toLowerCase() == 'service') {
        this.formData.email_cc = 'gaurav.kumar@basiq360.in,vikas@abacusdesk.co.in,sailesh@abacusdesk.co.in,asaph@basiq360.com';
      }
      else if (userSession.abacusdesk_user.department.toLowerCase() == 'dispatch') {
        this.formData.email_cc = 'mehnaz@basiq360.in,vikas@abacusdesk.co.in,sailesh@abacusdesk.co.in,asaph@basiq360.com';
      }
    }
    if (this.from == 'projectList' && data.type == 'reports_of_project_team_data') {
      this.search.date_from = data.data.search.date_from;
      this.search.date_to = data.data.search.date_to;
      this.search.project_name = data.data.data.project_name;
      this.clientTaskDetails();
    }

  }


  ngOnInit() {
    if (this.from == 'projectList' && (this.type == 'asignTask' || this.type == 'asignTaskMultiple')) {

      this.formData.required_developer = this.data.required_developer;
      this.GetAssignUserList('')

    } else if (this.from == 'projectList' && this.type == 'addNewTask') {

      this.suggestDev()
      if (this.data.modalType == 'clientLogin') {
        if (this.data.modalType == 'clientLogin' && this.data.task_data.task_type == 'Issue') {
          this.task_type = this.data.task_data.task_type
          this.maxDate = moment(this.data.task_data.expected_estimation_date).format('YYYY-MM-DD');
        }
        this.formData.project_name = this.data.task_data.project_name;
        this.formData.task_creation_type = 'Client';
        this.formData.project_id = this.data.project_id;
        this.formData.task = this.data.task_data.task;
        this.formData.task_priority = this.data.task_data.priority;
        this.formData.contact_person = this.data.task_data.contact_person;
        this.formData.contact_person_mobile = this.data.task_data.contact_person_mobile;
        this.formData.required_developer = this.data.task_data.required_developer;
        this.formData.pending_task = this.data.sub_task_id;
        this.formData.task_type = this.data.task_type;
        this.formData.task_type = this.data.task_type;
        this.GetProjectList('')
        this.findProjectId(this.formData.project_id);
        this.getPendingTask('');
        this.GetAssignUserList('');
        console.log("addnewtask")
        this.getDepartment()
      }
      else {
        console.log("addnewtask")
        this.GetProjectList('')
        this.GetAssignUserList('');
        this.getDepartment()
      }
    }
  }

  GetAssignUserList(event) {
    this.filter.name = event
    this.db.FetchData({ 'task_id': this.formData.pending_task, 'required_developer': this.formData.required_developer, 'project_id': this.project_id, 'filter': this.filter, 'department': this.userSession.abacusdesk_user.department }, 'master/GetUserListWithSelected_V2')
      .subscribe(result => {

        if (this.data.from == 'projectList' && this.data.type == 'asignTask') {
          this.user_list = result['user'];
          for (let i = 0; i < this.user_list.length; i++) {
            for (let j = 0; j < this.data.task.length; j++) {
              if (this.user_list[i]['id'] == this.data.task[j]['assign_id']) {
                this.user_list[i]['working_hours'] = this.data.task[j]['working_hours'];
                this.user_list[i]['working_minutes'] = this.data.task[j]['working_minutes'];
              }
            }
          }
        }
        else {
          this.user_list = result['user'];
        }



      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }



  suggestDev() {
    this.db.FetchData({ 'project_id': this.data.project_id, 'department': this.userSession.abacusdesk_user.department }, 'Automation/suggestedDeveloper')
      .subscribe(result => {
        if (result['statusCode'] == 200) {
          this.loader = false;
          this.sugDeveloper = result['data']
        }
        else {
          this.loader = false;
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }


  getProjectDetails() {
    this.loader = true;
    this.db.FetchData({ 'project_id': this.info.id, 'session': this.userSession.abacusdesk_user, 'filter': this.filter }, 'master/projectDetails')
      .subscribe(result => {
        if (result['statusCode'] == 200) {
          this.loader = false;
          this.projectDetails = result['data'];
          this.overAll = result['overall_totals'];
        }
        else {
          this.loader = false;
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }



  assigningTask() {
    this.loader = true;


    if (this.formData.assign_date) {
      this.formData.assign_date = moment(this.formData.assign_date).format('YYYY-MM-DD');
    }

    let Ids
    if (this.data.type == 'asignTask') {
      Ids = [{ 'id': this.task_id }]
    }
    if (this.data.type == 'asignTaskMultiple') {
      Ids = this.data.assignId
    }

    this.db.FetchData({ 'data': this.formData.assign_to, 'assign_date': this.formData.assign_date, 'task_id': Ids, 'project_id': this.project_id, 'session': this.userSession.abacusdesk_user }, 'master/reassign_task_V2')
      .subscribe(result => {
        if (result['status'] == 'Success') {
          this.loader = false;
          this.dialogRef.close(true);
          this.dialog.success('User Assigned', ' Successfully !');
        }
        else {
          this.loader = false;
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }

  reopenTask() {
    this.formData.project_id = this.project_id;
    this.formData.task_id = this.data.id;
    this.loader = true;
    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/reopen_task')
      .subscribe(result => {
        if (result['msg'] == 'success') {
          this.loader = false
          this.dialogRef.close(true);
          this.dialog.success('Task Re-Opened', ' Successfully !');
        }
        else {
          this.loader = false
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
        this.loader = false;
      });
  }
  holdTask() {
    this.loader = true
    this.formData.task_id = this.task_id;

    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/hold_task')
      .subscribe(result => {
        if (result['msg'] == 'success') {
          this.loader = false
          this.dialogRef.close(true);
          this.dialog.success('Task is on', ' Hold !');
        }
        else {
          this.loader == false
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
        // this.loading = false;
      });
  }

  editTask() {
    this.loader = true
    this.formData.task_id = this.data.id;
    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/taskEdit')
      .subscribe(result => {
        if (result['status'] == 'Success') {
          this.loader = false
          this.dialogRef.close(true);

          this.dialog.success('Task Edited', ' Successfully !');
        }
        else {
          this.loader = false
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {

        this.dialog.error('Something went wrong... Try Again !');

      });
  }
  GetProjectList(event) {
    this.filter.company_name = event
    this.db.FetchData({
      'filter': this.filter, 'session': this.userSession.abacusdesk_user,
    }, (this.userSession.abacusdesk_user.access_level == '2' || this.userSession.abacusdesk_user.access_level == '4') ? 'master/project_list' : 'master/new_project_list').subscribe((result) => {
      //   },'master/project_list').subscribe((result)=> {

      this.project_list = result['project_list'];

    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }
  submit_task(myForm: any) {

    this.loader = true
    this.formData.session = this.userSession.abacusdesk_user.id;
    this.formData.assign_date = moment(this.formData.assign_date).format('YYYY-MM-DD');

    this.db.FileData(this.formData, 'master/add_task')
      .subscribe(result => {
        if (result['msg'] == 'success') {
          this.loader = false
          this.dialogRef.close(true);
          this.dialog.success('Task Assigned', ' Successfully !');
        }
        else {
          this.loader = false
          this.dialog.error(result['msg']);
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');

      });


  }

  updateUser() {
    this.loader = true;
    this.db.FetchData({ 'data': this.user_form }, 'master/update_user')
      .subscribe(result => {
        if (result['msg'] == 'success') {
          this.dialog.success('User Updated', 'Successfully');
          this.loader = false;
          this.dialogRef.close(true);
        }
        else {
          this.loader = false;
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }


  date_format(): void {
    this.filter.assign_date = moment(this.filter.assign_date).format('YYYY-MM-DD');
    this.getTask();
  }

  userTask: any = [];
  totalHours: any;
  getTask() {
    this.loader = true;
    this.db.FetchData({ 'data': { 'user_id': this.data.task.id, 'assign_date': this.filter.assign_date } }, this.data.pageType == 'dashbord' ? 'Dashboard/userTaskList' : 'master/user_task_list')
      .subscribe(result => {
        this.userTask = result['task_list'];
        this.totalHours = result['total_working_hours'];

        this.loader = false;

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }

  getTeam() {
    this.db.FetchData({}, "Rewards/teamList").subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.teamData = result['team'];
      }
      else {
      }
    });
  }


  projectType: any = [];
  getDepartment() {
    let id
    if (this.type == 'updateProjectType') {
      id = this.project_id;
    }
    else {
      id = this.userSession.abacusdesk_user.access_level == '1' ? this.formData.project_id : this.userSession.abacusdesk_user.id
    }
    this.projectType = [];
    this.db.FetchData({ 'project_id': id }, "master/getClientDepartment").subscribe((result) => {
      if (result['statusCode'] == 200) {
        for (let i = 0; i < result['data'].length; i++) {
          this.projectType.push({ 'department_name': result['data'][i]['department_name'], 'id': result['data'][i]['id'].toString() })
        }


        if (this.userSession.abacusdesk_user.access_level != 'client') {
          for (let i = 0; i < this.projectType.length; i++) {
            if (this.projectType[i]['department_name'] == this.userSession.abacusdesk_user.department) {
              this.formData.department_id = this.projectType[i]['id'];
              this.formData.department_name = this.projectType[i]['department_name'];
            }
          }
        }




      }
      else {
        this.dialog.error('Something went wrong... Try Again !');
      }
    });
  }


  file: any = {};
  file_name: any;
  imageError: any = '';
  typecheck: any = '';
  istrue: boolean = false;
  selectedFile: any = [];


  onUploadChange(data: any) {
    if (this.taskImage.length > 4) {
      this.dialog.error('Maximum five image allowed');
      return;
    }
    else {
      for (let i = 0; i < data.target.files.length; i++) {
        let files = data.target.files[i];
        let byte = 1000000
        if (files.size > (byte * 2)) {
          this.dialog.error('Image size is too be large, maximum file size is 2 MB.');
          return;
        }
        if (files) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.taskImage.push(e.target.result);
          }
          reader.readAsDataURL(files);
        }
        this.image.append("" + i, data.target.files[i], data.target.files[i].name);
      }
      this.fileInput.nativeElement.value = '';
    }

  }

  deleteProductImage(i) {
    this.selectedFile.splice(i, 1)
    // this.taskImage.splice(i, 1)
  }


  getDeveloper(data) {
    this.developer = [];
    this.data.today_working_points = '';
    this.data.negative_points = '';
    this.data.bonus_points = '';
    this.data.date_created = '';

    this.db.FetchData({ 'team_id': data }, "Rewards/teamUserList").subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.developer = result['user'];
      }
      else {
      }
    });
  }


  formBlank() {
    this.data.today_working_points = '';
    this.data.negative_points = '';
    this.data.bonus_points = '';
    this.data.date_created = '';
    this.data.final_points = '';
  }

  submit() {
    this.savingFlag = true;
    this.formData.date_created = moment(this.formData.date_created).format('YYYY-MM-DD');
    this.db.FetchData({ 'data': this.formData }, 'Rewards/savePoints').subscribe(result => {
      if (result['statusCode'] == 200) {
        this.savingFlag = false;
        this.formData.point = '';
        this.formData.remark = '';
        // this.dialogRef.close(true);
        // this.data = {};
        this.dialog.success('Success', result['statusMsg']);
      }
      else {
        this.savingFlag = false;
        this.dialog.error(result['statusMsg']);
      }
    });
  }

  getPoint() {
    this.formData.date_created = moment(this.formData.date_created).format('YYYY-MM-DD');
    this.db.FetchData({ 'date_created': this.formData.date_created, 'user_id': this.formData.user_id, 'team_id': this.formData.team_id }, "Rewards/pointsData").subscribe((result) => {
      if (result['statusCode'] == 200) {
        this.formData.final_points = result['balance'] ? result['balance'] : '0';
      }
      else {
      }
    });
  }



  close() {
    this.dialogRef.close();
  }

  updateDisputeRemark() {
    this.loader = true;
    this.db.FetchData({ 'task_id': this.data.task, 'dispute_remark': this.formData.dispute_remark, 'session': this.userSession.abacusdesk_user }, 'master/dispute_multiple_task')
      .subscribe(result => {
        this.loader = false;
        this.dialogRef.close(true);
        this.dialog.success('Success', '');

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
        this.loader = false;
      });
  }



  findDepartment(id) {
    let index
    index = this.projectType.findIndex(row => row.id == id);
    console.log(index, 'index');

    if (index != -1) {
      this.formData.department_name = this.projectType[index]['department_name'];
    }
    console.log(this.formData.department_name, 'this.formData.department_name');
    console.log(this.formData.department_id, 'this.formData.id');


  }
  findProjectId(id) {
    let index
    index = this.project_list.findIndex(row => row.id == id);
    if (index != -1) {
      if (this.userSession.abacusdesk_user.access_level == '4' || this.userSession.abacusdesk_user.access_level == '1') {
        this.formData.project = this.project_list[index]['project_name'];
        this.formData.project_name = this.project_list[index]['project_name'];
      }
      else {
        this.formData.pending_project_id = this.project_list[index]['id']
      }
    }

  }

  findmobile(name) {
    let index
    index = this.contactPerson.findIndex(row => row.name == name);
    if (index != -1) {
      this.formData.contact_person_mobile = this.contactPerson[index]['mobile'];
    }

  }

  fillDescription(id) {
    let index
    index = this.pendingTask.findIndex(row => row.id == id);
    if (index != -1) {
      this.formData.task = this.pendingTask[index]['task']
    }
  }
  findData(id) {
    let index
    index = this.pendingTask.findIndex(row => row.id == id);
    if (index != -1) {
      this.formData.task_priority = this.pendingTask[index]['priority'];
      this.formData.required_developer = this.pendingTask[index]['required_developer'];
      this.formData.contact_person = this.pendingTask[index]['contact_person'];
      this.formData.contact_person_mobile = this.pendingTask[index]['contact_person_mobile'];

      this.GetAssignUserList('');
    }
  }

  getUserData(id) {
    let index
    index = this.developer.findIndex(row => row.id == id);
    if (index != -1) {
      this.formData.user_role = this.developer[index]['role'];
      this.formData.user_name = this.developer[index]['name'];

    }
  }

  submitClientTask() {

    if (this.selectedFile.length > 5) {
      this.dialog.error('Maximum five attachment allow same time');
      return;
    }
    if (this.userSession.abacusdesk_user.access_level == 'client') {
      this.formData.project_id = this.userSession.abacusdesk_user.id;
      this.formData.project_name = this.userSession.abacusdesk_user.project_name;
    }

    if (this.userSession.abacusdesk_user.access_level == '4') {
      this.formData.department_id = this.userSession.abacusdesk_user.id;
      this.formData.department_name = this.userSession.abacusdesk_user.department;
    }
    this.loader = true;

    let header
    let msgText
    if (this.type == 'clientTaskAdd') {
      header = this.db.FetchData({ 'data': this.formData, 'task_img': this.taskImage, 'session': this.userSession.abacusdesk_user }, "master/client_add_task");
      msgText = 'Task added';
    }
    if (this.type == 'clientTaskEdit' || this.type == 'updateProjectType') {
      if (this.formData.task_type == 'Issue') this.formData.priority = 'High'
      header = this.db.FetchData({ 'data': this.formData, 'task_img': this.taskImage, 'session': this.userSession.abacusdesk_user }, "master/client_edit_task");
      msgText = 'Task Updated';
    }


    header.subscribe(result => {
      if (result['msg'] == 'success') {

        if (this.type == 'clientTaskAdd') {
          if (this.selectedFile.length > 0) {
            this.uploadDocument(result['task_id']);
          }
          else {
            this.loader = false
            this.dialogRef.close(true);
            this.dialog.success(msgText, ' Successfully!');
          }
        }
        else {
          this.loader = false
          this.dialogRef.close(true);
          this.dialog.success(msgText, ' Successfully!');
        }


      }
      else {
        this.loader = false
        this.dialog.error('Something went wrong... Try Again !');
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }


  updateTaskDate() {
    this.loader = true;
    this.formData.estimated_time = moment(this.formData.estimated_time).format('YYYY-MM-DD');
    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, this.userSession.abacusdesk_user.access_level == '1' ? "master/client_update_estimate" : "master/client_add_estimate").subscribe(result => {
      if (result['msg'] == 'success') {
        this.loader = false
        this.dialogRef.close(true);
        this.dialog.success('Updated', ' Successfully!');
      }
      else {
        this.loader = false
        this.dialog.error('Something went wrong... Try Again !');
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }


  pendingTask: any = [];
  getPendingTask(event) {
    this.filter.task = event
    this.db.FetchData({

      'filter': this.filter, 'session': this.userSession.abacusdesk_user, 'project_id': this.formData.project_id,
    }, 'master/ClientTaskListFromDropdown').subscribe((result) => {
      this.pendingTask = result['task_list'];

    }, error => {

      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }




  rating(star) {
    this.ratingCheck = false
    this.star = star;
  }

  updateFeedabck() {

    console.log(this.userSession.abacusdesk_user, 'this.userSession.abacusdesk_user');


    if (this.formData.task_status == 'Complete') {

      if (this.star == 0) {
        this.ratingCheck = true;
        alert(';rating required');
        return
      }
      this.formData.rating = this.star;
      this.db.FetchData({ 'id': this.data.id, 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/client_close_task')
        .subscribe(result => {
          if (result['msg'] == 'success') {
            this.loader = false
            this.dialogRef.close(true);
            this.dialog.success('Updated', ' Successfully!');
          }
          else {
            this.dialog.error('Something went wrong... Try Again !');
          }


        }, error => {
          this.dialog.error('Something went wrong... Try Again !');
        });
    }

    else {
      this.db.FetchData({ 'id': this.data.id, 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/client_reopen_task')
        .subscribe(result => {
          if (result['msg'] == 'success') {
            this.loader = false
            this.dialogRef.close(true);
            this.dialog.success('Updated', ' Successfully!');
          }
          else {
            this.dialog.error('Something went wrong... Try Again !');
          }


        }, error => {
          this.dialog.error('Something went wrong... Try Again !');
        });
    }
  }



  loading: boolean = false;


  submitRemark() {
    this.user_form.task_id = this.data.task_id;
    this.db.FetchData({ 'data': this.user_form, 'session': this.userSession.abacusdesk_user }, 'master/clientTaskRemarkDirect')
      .subscribe((result) => {
        if (result['msg'] == "success") {
          this.loading = false;
        }
      }, error => {
        this.loading = false;
        this.dialog.error('Something went wrong !!! Try Again...');
      });

  }



  // upload function start
  onFileSelected(event: any) {
    const files = event.target.files;
    if (files.length > 5 || this.selectedFile.length > 5) {
      this.dialog.error('Maximum five attachment allow same time');
      event.target.value = null;
      return;
    }

    let byte = 1000000
    if (event.target.files[0]['size'] > (byte * 5)) {
      this.dialog.error('size is too be large, maximum file size is 5 MB.');
      return;
    }

    for (var i = 0; i < event.target.files.length; i++) {
      const allowedFormats = ['.jpg', '.jpeg', '.png', '.csv', '.pdf', '.docx', '.xlsx', '.txt'];
      const fileFormat = event.target.files[i].name.substring(event.target.files[i].name.lastIndexOf('.'));
      if (allowedFormats.includes(fileFormat.toLowerCase())) {
        this.selectedFile.push(event.target.files[i]);
        this.fileFormat = false;
      } else {
        this.fileFormat = true;
        this.dialog.error('File format not correct');
      }
    }

  }


  binaryData = new FormData();
  uploadDocument(task_id) {
    for (var i = 0; i < this.selectedFile.length; i++) {
      this.binaryData.append("image" + i, this.selectedFile[i], this.selectedFile[i].name);
    }

    this.db.FileData(this.binaryData, "Master/insertDoc/" + task_id + '/' + this.userdata.abacusdesk_user.id).subscribe((resp) => {
      if (resp['statusCode'] == 200) {
        this.dialogRef.close(true);
        this.dialog.success('Task added', ' Successfully!');
        this.loader = false
        this.savingFlag = false;
      } else {
        this.dialog.error(resp['statusMsg']);
        this.savingFlag = false;
      }
    });
  }




  applyLeave() {
    this.savingFlag = true;
    this.formData.leave_type = 'Leave';
    this.formData.user_id = this.data.user_id;
    this.formData.user_name = this.data.user_name.replaceAll('%20', ' ');

    this.formData.date_from = moment(this.formData.date_from).format('YYYY-MM-DD');
    this.formData.date_to = moment(this.formData.date_to).format('YYYY-MM-DD');
    this.db.FetchData({ 'data': this.formData }, 'Master/addLeave').subscribe(result => {
      if (result['statusCode'] == 200) {
        this.savingFlag = false;
        this.dialogRef.close(true);
        this.data = {};
        this.dialog.success('Success', result['statusMsg']);
      }
      else {
        this.savingFlag = false;
        this.dialog.error(result['msg']);
      }
    });
  }

  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) { event.preventDefault(); }
  }


  addContact() {
    this.formData.project_id = this.project_id;
    this.loader = true;
    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, "master/projectContactAdd").subscribe(result => {
      if (result['msg'] == 'Success') {
        this.loader = false;
        this.getList();
      }
      else {
        this.loader = false
        this.dialog.error('Something went wrong... Try Again !');
      }

    }, error => {
      this.dialog.error('Something went wrong... Try Again !');

    });


  }


  contactPerson: any = []

  getList() {
    this.loader = true;
    if (this.userSession.abacusdesk_user.access_level == 'client') {
      this.project_id = this.userSession.abacusdesk_user.id
    }


    this.db.FetchData({ 'project_id': this.formData.project_id ? this.formData.project_id : this.project_id }, "master/projectContactDetail").subscribe(result => {
      if (result['statusCode'] == 200) {
        this.contactPerson = result['data']
        this.loader = false;
      }
      else {
        this.loader = false
        this.dialog.error(result['statusMsg']);
      }
    });
  }

  delete(id) {
    this.dialog.alert('Alert', 'Are you sure you want to delete it?').then((result) => {
      if (result) {
        this.db.FetchData({ 'id': id }, "master/projectContactDelete").subscribe(result => {
          if (result['msg'] == 'Success') {
            this.dialog.success('Success', '');
            this.getList();
          }
          else {
            this.loader = false
            this.dialog.error(result['msg']);
          }
        });
      }
    });



  }


  getTaskListLogs() {
    this.loader = true;
    this.db.FetchData({ 'taskData': this.info }, 'master/logListApi')
      .subscribe(result => {
        this.logsInfo = result;
        console.log(this.logsInfo);

        this.loader = false;

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');
      });
  }


  updateQuotationStatus() {

    this.loader = true
    this.formData.session = this.userSession.abacusdesk_user.id;
    if (this.selected_image.length > 0) {
      this.formData.selected_image = this.selected_image;
    }

    this.db.FileData(this.formData, 'master/updateQuotationStatus')
      .subscribe(result => {
        if (result['statusCode'] == 200) {
          this.loader = false
          this.dialogRef.close(true);
          this.dialog.success('Updated', ' Successfully !');
        }
        else {
          this.loader = false
          this.dialog.error('Something went wrong... Try Again !');
        }

      }, error => {
        this.dialog.error('Something went wrong... Try Again !');

      });

  }

  sendEmail() {
    this.savingFlag = true;
    this.db.FetchData({ 'data': this.formData, 'session': this.userSession.abacusdesk_user }, 'master/sendMailToQuotation').subscribe((resp) => {
      if (resp['statusCode'] == 200) {
        this.savingFlag = false;
        this.dialog.success("Quotation", resp['statusMsg'])
        this.dialogRef.close(true);
      } else {
        this.savingFlag = false;
        this.dialogRef.close(true);
        this.dialog.error(resp['statusMsg'])

      }

    })
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dayOfMonth = d.getDate();
    const isSecondSaturday = day === 6 && dayOfMonth >= 8 && dayOfMonth <= 14;
    const lastDayOfTheMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    const lastweek = lastDayOfTheMonth.getDate() - 6;

    const isLastSaturday = (day === 6 && dayOfMonth >= lastweek && dayOfMonth <= lastDayOfTheMonth.getDate());
    console.log(isLastSaturday)

    return day !== 0 && !isSecondSaturday && !isLastSaturday;
  };

  deleteInvoiceImage(arrayIndex) {
    this.selected_image.splice(arrayIndex, 1);
  }

  onUploadChange2(data: any) {
    this.errorMsg = false;

    for (let i = 0; i < data.target.files.length; i++) {
      let files = data.target.files[i];
      if (files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.selected_image.push({ "image": e.target.result });
        }
        reader.readAsDataURL(files);
      }
      console.log(this.selected_image)
    }
  }

  download_sample_excel() {
    window.location.href = (this.db.DbUrl + 'uploads/barcode.csv')
  }

  uploadUexcel() {
    this.dialogRef.disableClose = true;
    let formData1 = new FormData();
    formData1.append('file', this.file, this.file.name);
    for (const key in this.formData) {
      formData1.append(key, this.formData[key]);
    }
    this.excel_loader = true;
    this.db.FileData(formData1, 'rewards/uploadPoints')
      .subscribe(d => {

        this.dialogRef.disableClose = false;
        formData1 = new FormData();
        if (d['statusCode'] == 200) {
          this.dialog.success("Excel Uploaded", d['statusMsg']);
          this.dialogRef.close(true);
          setTimeout(() => {
            this.excel_loader = false;
          }, 700);
          return;
        }
        else {
          setTimeout(() => {
            this.excel_loader = false;
          }, 700);
          this.dialog.error(d['statusMsg']);
          return;
        }

      }, err => { });
  }

  onUploadChange3(evt) {

    this.file = evt.target.files[0];
    this.excel_name = this.file['name'];

  }

  getValue() {
    console.log(this.search);
    this.dialogRef.close({ success: true, search: this.search });

  }

  public date() {
    if (this.search.date_from) {
      this.search.date_from = moment(this.search.date_from).format('YYYY-MM-DD');
    }
    if (this.search.date_to) {
      this.search.date_to = moment(this.search.date_to).format('YYYY-MM-DD');
    }
  }



  clientTaskDetails() {
    this.loader = true;
    this.db.FetchData({ 'session': this.userSession.abacusdesk_user, 'filter': this.search, 'type': 'report' }, 'master/clientTaskDetails').subscribe((result) => {
      console.log(result['overall_totals']);
      this.projectDetails = result['data']
      this.overAll = result['overall_totals']
      this.loader = false;
    }, error => {
      this.dialog.error('Something went wrong !! Try Again ...');
    });

  }



}

