import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from '../DialogComponent';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html'
})
export class DashboardModalComponent implements OnInit {
  loader: boolean = false
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: DialogComponent, public db: DatabaseService) {

    if (this.data.model_type == 'Team Task') {
      this.getTeamTask();
    }

    if (this.data.model_type == 'Project List') {
      this.getProjectList();
    }
  }

  ngOnInit() {
  }


  task: any = []
  getTeamTask() {
    this.loader = true;
    this.db.FetchData({ 'project_id': this.data.id, 'department': this.data.department }, 'Dashboard/taskListArray')
      .subscribe(result => {
        this.task = result['data'];
        this.loader = false;

      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
        this.loader = false;
      });
  }

  project: any = [];
  getProjectList() {
    this.loader = true;
    this.db.FetchData({ 'type': this.data.type }, 'Dashboard/projectListArray')
      .subscribe(result => {
        this.project = result['data'];
        this.loader = false;

      }, error => {
        this.dialog.error('Something went wrong... Try Again !!!');
        this.loader = false;
      });
  }

}
